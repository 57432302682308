import React from "react";
import ReactDOM from "react-dom";
import { HtmlUtils } from "../HtmlUtils";
import { CircleSpinner } from './CircleSpinner';

export default class SpinnerCore
{

    //=================================================================================================================
    protected _spinnerSize:number   = 60;
    protected _spinnerColor:string  = "#33B9B9";
    private   _spinnerDomElement = null;
    private   _spinnerContainerElement = null;
    private   _spinnerReactNode=null;
    private   _DOMContent:HTMLDivElement;
    private   _divElementSpinnerCaption:string = null;
    //=================================================================================================================

    public init(spinnerContainerId:string,spinnerPanelId:string,spinnerLabelId:string)
    {

        this._spinnerContainerElement   = document.getElementById(  spinnerContainerId  );
        this._spinnerDomElement         = document.getElementById(  spinnerPanelId      );
        
        this._divElementSpinnerCaption  = spinnerLabelId;
    }
    public initColor( color:string )
    {
        this._spinnerColor = color;
    }
    public initSize ( size:number )
    {
        this._spinnerSize = size;
    }
    //=================================================================================================================

    public show(bShow:boolean,infoText:string = null)
    {
        if (bShow)
        {
            if (this._spinnerDomElement && this._spinnerContainerElement)
            {
                this._spinnerDomElement.style.margin  = "15px";

                if (infoText !== null)
                {
                    HtmlUtils.showHtmlElement( this._divElementSpinnerCaption,  true);
                    HtmlUtils.setText( this._divElementSpinnerCaption,  infoText);
                }
                this._spinnerReactNode = ReactDOM.render( <CircleSpinner color={ this._spinnerColor } size={ this._spinnerSize }/>,  this._spinnerDomElement);
            }
        }
        else
        {
            HtmlUtils.showHtmlElement(  this._divElementSpinnerCaption,  false);
            this._spinnerDomElement.style.margin  = "0px";
            ReactDOM.unmountComponentAtNode(this._spinnerDomElement)
            this._spinnerReactNode = null  
        }
    }

    //=================================================================================================================

    public getSpinnerCaptionId()
    {
        return this._divElementSpinnerCaption;
    }

    //=================================================================================================================
}