import './css/BimCadExtension.css';
import {ExtensionUtil} from '../utils/utils'
import React from 'react';
import ReactDOM from 'react-dom';
import BimCadGUIPanel from "./components/BimCadGUIPanel";
import ItemNumbersMainGUIPanel from "./components/ItemNumbersMainGUIPanel";
import Axios from 'axios';
import { Client } from '../../../client';

export class BimCadExtensionPanel extends Autodesk.Viewing.UI.DockingPanel 
{
    //=================================================================================================================
    protected _initialXPos   = "80";
    protected _initialYPos   = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    private   _DOMContent:HTMLDivElement;
    private   _wrapper:HTMLDivElement;
    private   _reactNode:any;
    private   _guiPanel:BimCadGUIPanel = null;
    private   _spinnerColor:string  = "#FF0000";
    private   _spinnerSize:number   = 10;
    private   _axiosInstance = null;
    private   _axiosBaseUrl  = null;
    private   _apiClient     = null;
    //=================================================================================================================

    constructor(viewer:any, container:any, id:any, title:any, options:any)  
    {
        super(viewer.container, id, title, options);
        
        Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);

        if (ExtensionUtil.isContentNotNull(options.init_options.BASE_URL))
        {
            this._axiosBaseUrl = options.init_options.BASE_URL;
        }
        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
        {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4)
            {
              this._initialXPos   = xyCoords[0];
              this._initialYPos   = xyCoords[1];
              this._initialWidth  = xyCoords[2];
              this._initialHeight = xyCoords[3];
            }
        }
        if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_COLOR))
        {
            this._spinnerColor = options.init_options.SPINNER_COLOR;
        }   
        if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_SIZE))
        {
            const spinnerSize = options.init_options.SPINNER_SIZE;
            this._spinnerSize = parseInt(spinnerSize,10);
            if (this._spinnerSize > 120)
                this._spinnerSize = 120;
            if (this._spinnerSize < 8)
                this._spinnerSize = 8;
        }   

        this.createAxiosInstance();

       this.container.classList.add("docking-panel-container-solid-color-a");
       this.container.style.width       =  this._initialWidth +"px";  
       this.container.style.height      =  this._initialHeight +"px"; 
       this.container.style.minWidth    = "550px";
       this.container.style.minHeight   = "50px";
       this.container.style.resize      = "none";
       this.container.style.position    = "absolute";
       this.container.style.left        = this._initialXPos + "px";
       this.container.style.top         = this._initialYPos + "px";

        //=================================================================================
     
        this.container.classList.add('react-uni-docking-panel')
        //=================================================================================
        this._DOMContent = document.createElement('div')
        this._DOMContent.setAttribute("id", "default-dp-bimcad");
        this._DOMContent.className = 'content'
        //=================================================================================
        this._wrapper = document.createElement('div');
        this._wrapper.setAttribute("class", "bimcadcontainer flex-column d-flex p-0 m-0");
        this._wrapper.appendChild(this._DOMContent );
        //=================================================================================
        this.container.appendChild( this._wrapper ); 
        //=================================================================================
  
    }

    //===================================================================================================================

    public initialize() : void {

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);
  
        this.initializeMoveHandlers(this.title);
  
        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);
    
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);
    }
  
    //=================================================================================================================

    private createAxiosInstance()
    {
        if (this._axiosInstance == null)
        {
            this._axiosInstance = Axios.create({  baseURL: this._axiosBaseUrl });
            if (this._axiosInstance != null)
            {
                this._apiClient = new Client( this._axiosBaseUrl, this._axiosInstance );
            }
            else
            {
                alert("axios error");
            }
        }
    }    

    //=================================================================================================================

    public getApiClient()
    {
        return  this._apiClient;
    }    
    public setGUIPanel(guiPanel:BimCadGUIPanel)
    {
        this._guiPanel = guiPanel;
    }
    public getSpinnerColor() : string
    {
        return  this._spinnerColor;
    }
    public getSpinnerSize() : number
    {
        return  this._spinnerSize;
    }
    //=================================================================================================================

    public setTitle (title:string) 
    {
        this.title.innerText = title;
    }

    //=================================================================================================================

    public setVisible (show) 
    {
        // if ( this._guiPanel)
        // {
        //     //this._guiPanel.close();
        // }

        super.setVisible(show)

        if (show) 
        {
            this._reactNode = ReactDOM.render( <ItemNumbersMainGUIPanel parentPanel = {this} />, this._DOMContent)
        }
        else if (this._reactNode)
        {
            ReactDOM.unmountComponentAtNode(this._DOMContent)
            this._reactNode = null  
        }
    }
  
    //=================================================================================================================
}



