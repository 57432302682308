import '../css/BimCadExtension.css';
import * as React from 'react';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import SpinnerCore from '../../utils/GuiComponents/SpinnerCore';
import { AppBar, IconButton, Snackbar, Toolbar, Typography } from '@material-ui/core';
import  {SeverityType,VerticalOrigin,HorizontalOrigin, DialogUtil,g_darkThemeDialog } from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";
import Button from '@material-ui/core/Button';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { HtmlUtils } from '../../utils/HtmlUtils';
import PortofolioGUIPanel from './PortfolioGUIPanel';
import BimCadGUIPanel from './BimCadGUIPanel';
import ReactDOM from 'react-dom';
import { Alert } from '@material-ui/lab';
  

const Transition = React.forwardRef(function Transition(
    props:  TransitionProps & { children?: React.ReactElement<any, any> },
    ref:    React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});


interface ItemNumbersMainGUIPanelProps
{
   parentPanel;
}

interface ItemNumbersMainGUIPanelState
{
    showAlert:      boolean;
    alertTransition: any;
    alertMessage:    string;
    alertType:       SeverityType;
    alertDuration:   number;
    alertVOrigin:   VerticalOrigin;
    alertHOrigin:   HorizontalOrigin;
    activePage: number;
}

export default class ItemNumbersMainGUIPanel extends React.Component<ItemNumbersMainGUIPanelProps,ItemNumbersMainGUIPanelState>
{
    //============================================================
    private  _guiElementesInitialized=false;
    private  _spinner:SpinnerCore = null;
    private  _portfolioReactNode=null;
    private  _bimCadReactNode=null;
    //=============================================================

    constructor(prop:any)
    {
        super(prop);  

        this.state = { 
                       activePage:      1,
                       alertTransition: undefined, 
                       showAlert:       false, 
                       alertMessage:    "", 
                       alertType:       SeverityType.INFO, 
                       alertVOrigin:    VerticalOrigin.TOP, alertHOrigin: HorizontalOrigin.CENTER,
                       alertDuration:   5000 };


        this.props.parentPanel.setGUIPanel(this);
        this._spinner = new SpinnerCore();
    }  

    //=================================================================================================================

    componentDidMount = () => {

        this.onGuiLoaded();
        this.onSetBimCadPageActive();
    }

    //=================================================================================================================
  
    componentWillUnmount = () => { 

    }

    //=================================================================================================================

    private onGuiLoaded()
    {
        if (!this._guiElementesInitialized)
        {
            this._guiElementesInitialized = true;

            this._spinner.init("id-spinnerContainer-bimcad","id-spinnerPanel-bimcad","id-spinnerCaptionPanel-bimcad");
            this._spinner.initColor( this.props.parentPanel.getSpinnerColor() );
            this._spinner.initSize ( this.props.parentPanel.getSpinnerSize() );
        }
    }

    //=================================================================================================================

    public showSpinner(bShow:boolean)
    {
        this._spinner.show( bShow);
    }

    public showAppBar(bShow:boolean)
    {
       HtmlUtils.showHtmlElement("id-selector-container",bShow);
    }

    //=================================================================================================================

    public onClose = (event) => {

        this.props.parentPanel.setVisible(false);
    }

    //======================================================================================================================

    public showErrorMessage(errorMsg:string)
    {
        this.setState( {showAlert: true, alertType: SeverityType.ERROR,  alertMessage: errorMsg });
    }

    public showSuccessMessage(message:string)
    {
        this.setState( {showAlert: true, alertType: SeverityType.SUCCESS,  alertMessage: message });
    }    

    private onCloseAlertMessage = () => {

        this.setState( { showAlert: false } );
    };

    //======================================================================================================================

    private onSetBimCadPageActive = () => {

        this.setState( { activePage: 0 } );
        this.showPortofolioComponent(false);
        this.showBimCadComponent(true);

        this.props.parentPanel.setTitle("Bimcad-ItemNumbers");        
        //this.onSetActiveButton(false);
    };    

    //======================================================================================================================

    private onSetPortFolioPageActive = () => {

        this.setState( { activePage: 1 } );  
        this.showBimCadComponent(false);
        this.showPortofolioComponent(true);

        this.props.parentPanel.setTitle("Portfolio-ItemNumbers");
        //this.onSetActiveButton(true);

    };   

    //======================================================================================================================

    public onSetActiveButton(bActive:boolean)
    {
        const buttonElement = document.getElementById("id_portfolio_btn");

        if (buttonElement != null)
        {
            if ( bActive == false )
            {
                buttonElement.setAttribute("class", "btn btn-secondary btn-xyz2");
                alert("not active");
            }
            else
            {
                buttonElement.setAttribute("class", "btn btn-secondary btn-xyz2-active");
                alert("active");
            }
        }
    }

    //======================================================================================================================
    
    private showPortofolioComponent(bShow:boolean)
    {
        const domElement = document.getElementById(  "portfolio-container"  );

        if (bShow)
        {
            if (domElement)
            {
                this._portfolioReactNode = ReactDOM.render( <PortofolioGUIPanel parentPanel={ this } />,  domElement);
            }
        }
        else
        {
            ReactDOM.unmountComponentAtNode( domElement )
            this._portfolioReactNode = null  
        }
    }

    //=================================================================================================================
    
    private showBimCadComponent(bShow:boolean)
    {
        const domElement = document.getElementById(  "bimcad-container"  );
        if (bShow)
        {
            if (domElement)
            {
                this._bimCadReactNode = ReactDOM.render( <BimCadGUIPanel parentPanel={ this } />,  domElement);
            }
        }
        else
        {
            ReactDOM.unmountComponentAtNode( domElement )
            this._bimCadReactNode = null  
        }
    }

    //=================================================================================================================

    render() {

        let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
        let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
        let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

        let activePage = this.state.activePage;

        return (

            <div>

                <div>     
                <ThemeProvider theme={g_darkThemeDialog}>
                <Snackbar 
                    open                = { this.state.showAlert } 
                    TransitionComponent = { this.state.alertTransition }
                    autoHideDuration    = { this.state.alertDuration } 
                    anchorOrigin={{
                        vertical:   useVerticalOrigin,
                        horizontal: useHorizontalOrigin,
                    }}   
                    onClose = { this.onCloseAlertMessage }>

                    <Alert 
                        onClose  = { this.onCloseAlertMessage } 
                        severity = { useSeverity }>
                        { this.state.alertMessage }
                    </Alert>

                </Snackbar>
                </ThemeProvider>
                </div>
                       

                <div className="container m-0 p-0  flex-column d-flex" id="id-spinnerContainer-bimcad">
                    <div id="id-spinnerCaptionPanel-bimcad"/>
                    <div className="col-xs-6" id="id-spinnerPanel-bimcad"/>
                </div>


            <div className="container-fluid"  id="id-selector-container" >
                    <div className="row">

                        <div className="col">
                            <div>
                                <button className="btn  btn-secondary btn-xyz"
                                    onClick={ this.onSetBimCadPageActive} 
                                    type="button">BIMCad</button>
                            </div>
                        </div>
                      

                        <div className="col" >
                            <div>
                                <button className="btn  btn-secondary btn-xyz2" id="id_portfolio_btn"
                                    aria-pressed="false"
                                    onClick={ this.onSetPortFolioPageActive} 
                                    type="button">PortFolio</button>
                            </div>
                        </div>
                    </div>
                </div>
             

                <div className="portfolio-container"  id="portfolio-container"/>

                <div className="bimcad-container"  id="bimcad-container"/>
            
            </div>
        );
    }

//=================================================================================================================

}
