import React from "react";
import ReactDOM from "react-dom";
import TransformControls from "./components/TransformControls";

export class PlacementExtension extends Autodesk.Viewing.Extension {
	private _viewer: Autodesk.Viewing.GuiViewer3D | null = null;
	private _model: any = null;
    private _controlPanel: Autodesk.Viewing.UI.DockingPanel | null = null
    private _isActive: boolean = false
    private _subToolbar: any = null;

	public constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options);
		this._viewer = viewer;
	}

	public load(): boolean {
		if (!this._viewer) {
			return false;
		}
        this._viewer.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.createToolbarButton.bind(this));
		return true;
	}

    private onToolbarButtonClicked(e: any): void {
        if(!this._isActive){
            if(!this._controlPanel){
                this.createControlPanel();
            }
            this._controlPanel.setVisible(true)
        } else {
            this._controlPanel.setVisible(false)
        }
        this._isActive = !this._isActive
    }

    private createToolbarButton(): void {
        if (this._subToolbar == null) {
          // button to show the docking panel
          const actionToolbarButton = new Autodesk.Viewing.UI.Button(
            "PlacementButton"
          );
          const iconSpan = document.createElement('span');
          actionToolbarButton.setToolTip("PlacementExtension");
          iconSpan.className = 'fa-solid fa-up-down-left-right';
          iconSpan.style.fontSize = '24px'; // Legen Sie die Größe des Icons entsprechend Ihren Anforderungen fest.
          actionToolbarButton.icon.style.display = 'flex';
          actionToolbarButton.icon.style.alignItems = 'center';
          actionToolbarButton.icon.style.justifyContent = 'center';
          actionToolbarButton.icon.appendChild(iconSpan);
    
          actionToolbarButton.onClick = (e) => {
            this.onToolbarButtonClicked(e);
          };
    
          this._subToolbar = new Autodesk.Viewing.UI.ControlGroup(
            "PlacementExtensionToolbar"
          );
    
          this._subToolbar.addControl(actionToolbarButton);
          this.viewer.toolbar.addControl(this._subToolbar);
        }
      }

	private createControlPanel(): void {
		if (!this._viewer) {
			return;
		}
		const viewerContainer = this._viewer.container;
		const controlPanel = new Autodesk.Viewing.UI.DockingPanel(viewerContainer, "PlacementPanel", "Control Panel");
		controlPanel.container.style.width = "350px";
		controlPanel.container.style.height = "auto";
		controlPanel.container.style.top = "200px";
		controlPanel.container.style.right = "50px";
		const onModelSelected = (setModelSelected) => {
			this._viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, (event) => {
				if (!event.selections || event.selections.length === 0) {
					return;
				}
				this._model = event.selections && event.selections[0].model;
				setModelSelected(!!this._model);
			});
		};
		const onMove = (axis: string, value: number) => {
			this.moveModel(axis, value)
		}
	
		const onRotate = (axis: string, value: number) => {
			switch(axis) {
				case 'x':
				case 'y':
				case 'z':
					this.rotateModel(axis, value);
					break;
				default:
					break;
			}
		}
		ReactDOM.render(
			<TransformControls
				onModelSelected={onModelSelected}
				onRotate={onRotate}
				onMove={onMove}
			/>,
			controlPanel.container
		);
		controlPanel.setVisible(true);
        this._controlPanel = controlPanel;
	}

	public unload(): boolean {
		this._viewer?.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, this.onSelectionChanged.bind(this));
		return true;
	}

	private moveModel(axis: string, newValue: number) {
		if (!this._model) {
			return
		}
    // Decompose the transformation matrix
    const position = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();
    this._model.getPlacementTransform().decompose(position, quaternion, scale);
    
    // Get the current position
    let currentPosition = 0;
    switch(axis) {
        case 'x':
            currentPosition = position.x;
            break;
        case 'y':
            currentPosition = position.y;
            break;
        case 'z':
            currentPosition = position.z;
            break;
        default:
            break;
    }
    
    // Calculate the difference between the new and the current position
    const positionDifference = newValue - currentPosition;

    const tr = this._model.getPlacementTransform();
    switch(axis) {
        case 'x':
            tr.elements[12] += positionDifference;
            break;
        case 'y':
            tr.elements[13] += positionDifference;
            break;
        case 'z':
            tr.elements[14] += positionDifference;
            break;
        default:
            break;
    }

    this._model.setPlacementTransform(tr);
    this._viewer.impl.invalidate(true, true, true);
    this._viewer.impl.sceneUpdated(true);
	}

	private createRotationMatrix(axis: string, angleInDegrees: number): THREE.Matrix4 {
		const angleInRadians = (angleInDegrees * Math.PI) / 180;
		const rotationMatrix = new THREE.Matrix4();
		switch(axis) {
			case 'x':
				rotationMatrix.makeRotationX(angleInRadians);
				break;
			case 'y':
				rotationMatrix.makeRotationY(angleInRadians);
				break;
			case 'z':
				rotationMatrix.makeRotationZ(angleInRadians);
				break;
			default:
				break;
		}
		return rotationMatrix;
	}

	private currentAngles = { x: 0, y: 0, z: 0 }; // initial values

private rotateModel(axis: string, newValue: number): void {
	if (!this._model) {
		return;
	}
	// Decompose the transformation matrix
	const position = new THREE.Vector3();
	const quaternion = new THREE.Quaternion();
	const scale = new THREE.Vector3();
	this._model.getPlacementTransform().decompose(position, quaternion, scale);

	// Determine the change in the angle
	const deltaAngle = newValue - this.currentAngles[axis];

	// Update the current angle
	this.currentAngles[axis] = newValue;

	// Create a rotation quaternion for the new value
	let rotationQuaternion = new THREE.Quaternion();
	switch(axis) {
		case 'x':
			rotationQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), deltaAngle * Math.PI / 180);
			break;
		case 'y':
			rotationQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), deltaAngle * Math.PI / 180);
			break;
		case 'z':
			rotationQuaternion.setFromAxisAngle(new THREE.Vector3(0, 0, 1), deltaAngle * Math.PI / 180);
			break;
		default:
			break;
	}
	// Multiply the current quaternion with the rotation quaternion
	quaternion.multiply(rotationQuaternion);
	// Create a new transformation matrix
	const newTransform = new THREE.Matrix4();
	newTransform.compose(position, quaternion, scale);
	this._model.setPlacementTransform(newTransform);
	this._viewer.impl.invalidate(true, true, true);
	this._viewer.impl.sceneUpdated(true);
}

	private onSelectionChanged(event: any) {
		if (!event.selections || event.selections.length === 0) {
			return;
		}
		const getSelect = event.selections;
		this._model = getSelect[0].model;
		return;
	}
}
