import "../../utils/GuiComponents/base.css";
import '../css/UnifiExtension.css';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { ExtensionsError, ExtensionsInfo, ExtensionUtil } from '../../utils/utils';
import {Client, ContentDto, ContentParameterDto, ContentParameter, ItemNumberInformation, Library, Source} from  '../../../../client';
import Tabulator from 'tabulator-tables'; 
import { CircleSpinner } from '../../utils/GuiComponents/CircleSpinner';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { HtmlUtils } from '../../utils/HtmlUtils';
import { mui_darkTheme } from "../../utils/Material_UI_Themes";
import  {UnifiCore,StdIdWithNameStructure,ICLibDataExtStruct}  from "../../utils/UnifiCore";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
//import Slide, { SlideProps } from '@material-ui/core/Slide';
import { Alert } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { FormControlLabel, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';

import  {SeverityType,VerticalOrigin,HorizontalOrigin,DialogUtil,g_darkThemeDialog } from "../../utils/GuiComponents/ModalDialogs/BaseSnackbar";

import  {SourceNames } from "../../utils/UnifiCore";
import SpinnerCore from '../../utils/GuiComponents/SpinnerCore';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


interface UnifiExtensionGUIPanelProps 
{
    parentPanel;
}

interface UnifiExtensionGUIPanelState
{
    selectedOption;

    loadingLibraries:     boolean;
    libraries:            StdIdWithNameStructure[];

    loadingCurselLibrary: boolean;
    curselLibrary:        ICLibDataExtStruct[];
    curselLibraryName:    string;

    showSpinner: boolean;
    canLoad:    boolean;
    canExport:    boolean;
    acceptDuplicates: boolean;

    showAlert:      boolean;
    alertMessage:   string;
    alertVOrigin:   VerticalOrigin;
    alertHOrigin:   HorizontalOrigin;
    alertType:      SeverityType;
    alertTransition: any;
    alertDuration:number;

    showMessageBox:  boolean;
    dlgTitle:        string;
    dlgContentText:  string;

    showContextMenu: boolean
    anchorEl:        null;
    mouseX:  number,
    mouseY:  number,
}


const Transition = React.forwardRef(function Transition(
    props:  TransitionProps & { children?: React.ReactElement<any, any> },
    ref:    React.Ref<unknown>,
){
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class UnifiExtensionGUIPanel extends React.Component<UnifiExtensionGUIPanelProps,UnifiExtensionGUIPanelState>
{
    //============================================================
    private   _LibraryContentGrid=null;
    private   _csvFileDelimiter =";";
    private   _csvFileDelimiterName ="semicolon";
    private   _currentGridData:ICLibDataExtStruct[] = [];
    private   _hiddenColumns=[];
    private   _selectedOptions:StdIdWithNameStructure[] = [];
    private  _guiElementesInitialized=false;
    private  _captionAll  = "All";
    private  _dbFilterKey = "item number";
    private  _spinner:SpinnerCore = null;
    private  _itemNumberInfos:ItemNumberInformation[]=[];
    //=============================================================

    constructor(prop:any)
    {
        super(prop);  

        this.state = { selectedOption: null,    libraries: null, loadingLibraries: true,  
                       curselLibrary: null,  loadingCurselLibrary: true, curselLibraryName: "",  
                       showSpinner:true, canLoad: false, canExport: false, acceptDuplicates: false,
                       showAlert:       false, 
                       alertMessage:    "", 
                       alertHOrigin:    HorizontalOrigin.CENTER, 
                       alertVOrigin:    VerticalOrigin.TOP, 
                       alertType:       SeverityType.INFO, 
                       alertTransition: undefined, 
                       alertDuration:   6000,
                       showContextMenu: false,
                       anchorEl:        null,
                       mouseX: 0,
                       mouseY: 0,       
                       showMessageBox:  false,
                       dlgTitle:        "",
                       dlgContentText:  "" };

        this.props.parentPanel.setGUIPanel(this);

        this._spinner = new SpinnerCore();

        this.onExpandAllGridGroups   = this.onExpandAllGridGroups.bind(this);
        this.onCollapseAllGridGroups = this.onCollapseAllGridGroups.bind(this);
        this.onToggleCurselGridGroup = this.onToggleCurselGridGroup.bind(this);
    }  

    //=================================================================================================================

    componentDidMount = () => {

        this._spinner.init("id-spinnerContainer-unifi","id-spinnerPanel-unifi","id-spinnerCaptionPanel-unifi");
        this._spinner.initColor( this.props.parentPanel.getSpinnerColor() );
        this._spinner.initSize ( this.props.parentPanel.getSpinnerSize() );

        this.getLibrariesFromDatabase();  

    }

    //=================================================================================================================
  
    componentWillUnmount = () => { 

    }

    //=================================================================================================================

    private setButtonTooltips()
    {
        if (!this._guiElementesInitialized)
        {
            this._guiElementesInitialized = true;

            const toolTipInfo   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT_INFO);
            const buttonCaption = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_GET_UNIFI_LIBCONTENT);
            HtmlUtils.setButtonTextAndTooltip("id-button-getdata", null, toolTipInfo );

            const toolTipInfo2   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_UNIFI_EXPORT_RECORDS_INFO);
            HtmlUtils.setButtonTextAndTooltip("id-button-export", null, toolTipInfo2 );

            const toolTipInfo3   = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_RESET_GRID_COLOUMS_INFO);
            HtmlUtils.setButtonTextAndTooltip("id-button-reset", null, toolTipInfo3 );
        }
    }

    //=================================================================================================================

    

    private async getLibrariesFromDatabase()
    {
        this._spinner.show(true);

        const apiClient:Client = this.props.parentPanel.getApiClient();

        await apiClient.getContentParameterDataFromStorage(this._dbFilterKey).then( (dbContentParams:ContentParameter[]) =>  {

            let databaseLibrariesIds:string[] = [];
           
            for (let item of dbContentParams)
            {
                databaseLibrariesIds.push(item.libraryId);
            }


            if (dbContentParams.length > 0)
            {
                UnifiCore.getAllUnifiLibraries(this.props.parentPanel.getApiClient(),true,).then( (unifiLibraries:StdIdWithNameStructure[]) =>  {  

                    let available_libraries:StdIdWithNameStructure[] = [];
        
                    for (let currrentUnifiLib of unifiLibraries)
                    {
                        // check if this lib is in database
                        if (databaseLibrariesIds.includes(currrentUnifiLib.id))
                        {
                            const cx:StdIdWithNameStructure = {  id: currrentUnifiLib.id , name: currrentUnifiLib.name   };
                            available_libraries.push(cx);
                        }
                    }

                    if (available_libraries.length > 0)
                    {
                        // add "Select All item"
                        const cx1:StdIdWithNameStructure = {  id: "0" , name: this._captionAll   };
                        const tmp_Array:StdIdWithNameStructure[] = [];
                        tmp_Array.push(cx1);
                        available_libraries = [...tmp_Array, ...available_libraries];

                      //  this.getItemNumbersInformations();

                    }
                    this._spinner.show(false);

                    this.setState({ libraries: available_libraries,  loadingLibraries: false });

                    let   info  = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_CONTENT_STORAGE_LIBS_FOUND);
                          info  = info.replace("%", available_libraries.length.toString() );
        
                    this.setState( {showAlert: true, alertType:  SeverityType.SUCCESS, alertMessage: info });
                }) .catch((error) => { 
            
                    this._spinner.show(false); 
                    const info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
                    this.setState( {showAlert: true, alertType:  SeverityType.ERROR, alertMessage: info });
                });
            }
            else
            {
                this._spinner.show(false);
            }    
        })
        .catch((error) => { 
            this._spinner.show(false);
            let info = ExtensionUtil.getErrorText(ExtensionsError.ERROR_CONNECT_WEB_SERVER);
            info += "  [ " + error + " ]";
            this.setState( {showAlert: true, alertType:  SeverityType.ERROR, alertMessage: info });
        });
    }

    //=================================================================================================================

    private async getItemNumbersInformations() 
    {
        const apiClient:Client = this.props.parentPanel.getApiClient();

        await apiClient.getItemNumbersFromStorage().then( content =>  {

            this._itemNumberInfos = content;

        })
        .catch((error) => { 

            const errInfo = ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_ITEM_NUMBER_INFORMATION);    
            this.setState( {showAlert: true, alertType:  SeverityType.ERROR, alertMessage: errInfo });
        });
    }

    //=================================================================================================================

    private before_getLibraryByLibId() : void
    {
        HtmlUtils.showHtmlElement("id-libraries-container",false);
        this._spinner.show(true);
        this._currentGridData = [];
    }

    //=================================================================================================================

    private after_getLibraryByLibId(libName:string) : void
    {
        this._spinner.show(false);
        this.setupCurrentLibraryContensGrid(this._currentGridData,false);
        this.setState({ curselLibrary: this._currentGridData,  loadingCurselLibrary: false,  curselLibraryName: libName });
        HtmlUtils.showHtmlElement( "id-libraries-container",  true);
        if (this._currentGridData.length)
            this.setState({ canExport: true });
        const info        = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_UNIFI_EXPORT_RECORDS_INFO);
        const tooltipInfo = info.replace("%", this._currentGridData.length.toString());
        HtmlUtils.setButtonTextAndTooltip( "id-button-export", null , tooltipInfo);
    }    

   //=================================================================================================================
  
    private setupCurrentLibraryContensGrid(data,groupStartOpenFlag) 
    {
        const that = this;

        var headerMenu = [
            {
                label:"<i class='fas fa-eye-slash'></i> Hide Column",
                action:function(e, column)
                {
                    that._hiddenColumns.push(column.getField());
                    column.hide();
                    HtmlUtils.enableButton("id-button-reset", true);
                }
            },
        ];

        this._LibraryContentGrid = new Tabulator("#currentLibContentTable", {
            
            data:               data,   
            layout:             "fitColumns",   //fit columns to width of table (optional)
            //layout:             "fitDataStretch",
            height:             "560px",
            selectable:         1,
            groupStartOpen:     groupStartOpenFlag,
            groupToggleElement: "header",
            groupBy:            "Title",
            //rowContextMenu:      headerMenu, //add context menu to rows

            columns: [
              //  { title: "Typename",                    field: "Typename",     headerSort: true,  headerFilter: true , headerMenu:headerMenu,   width:"2%", widthGrow: 1, widthShrink: 1 },                   
                { title: "Title",                       field: "Title",        headerSort: true,  headerFilter: true , headerMenu:headerMenu,   width:"16%", widthGrow: 1, widthShrink: 1 },                   
                { title: "Filename",                    field: "Filename",     headerSort: true , headerFilter: true , headerMenu:headerMenu,   width:"16%", widthGrow: 1, widthShrink: 1 },   
                { title: "Product Description",         field: "ProdDesc",     headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"17%", widthGrow: 1, widthShrink: 1 }, 
                { title: "RSen_C_product Description",  field: "RSenCProdDesc",headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"14%", widthGrow: 1, widthShrink: 1 }, 
                { title: "Item-Number",                 field: "Value",        headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"10%", widthGrow: 1, widthShrink: 1 },
                { title: "Source",                      field: "Source",       headerSort: true,  headerFilter: true,  headerMenu:headerMenu,   width:"6%", widthGrow: 1, widthShrink: 1 },  
                { title: "Unifi",                      field:"NumberInUnifi",    width:"7%",   headerMenu:headerMenu,  hozAlign:"center", formatter:"tickCross", sorter:"boolean", editor:true},
                { title: "BimCad",                      field:"NumberInBIMCad",    width:"7%",   headerMenu:headerMenu,  hozAlign:"center", formatter:"tickCross", sorter:"boolean", editor:true},
                { title: "Portfolio",                   field:"NumberInPortfolio", width:"7%",   headerMenu:headerMenu,  hozAlign:"center", formatter:"tickCross", sorter:"boolean", editor:true}

            ],
        });  
    }

    //=================================================================================================================

    private onLoadSelectedLibraries = (e) => {

        this.loadSelectedLibraries();
    };    

    //=================================================================================================================

    async loadSelectedLibraries()
    {
        let errors:string[] = [];
        let warnings:string[] = [];

        if (this._selectedOptions.length > 0)
        {
            if (this._LibraryContentGrid != null)
            {
                this._LibraryContentGrid.destroy();
            }

            this.before_getLibraryByLibId();

            if (this._selectedOptions[0].name === this._captionAll)
            {
                await this.getSelectedContentParamters( this.state.libraries,errors, warnings, 1);   // start at index 1 ( 0=>All)             
            }
            else
            {
                await this.getSelectedContentParamters( this._selectedOptions, errors, warnings, 0);
            }
        
            if (warnings.length > 0)
            {
                let allWarnings = "";
                for (let currWarning of warnings)
                {
                    allWarnings += currWarning + "\n\r";
                }
                const title = ExtensionUtil.getInfoText(ExtensionsInfo.CAPTION_SOME_WARNINGS_OCCURED);
                this.setState( { showMessageBox: true, dlgTitle: title, dlgContentText: allWarnings });
            }             

            if (errors.length > 0)
            {
                let info    = ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_UNIFI_LIB_02);
                    info    = info.replace("%", errors.length.toString() );
                this.setState( {showAlert: true, alertType:  SeverityType.ERROR,  alertDuration: 8000,  alertMessage: info });
            } 
        }
    }
    
    //=================================================================================================================

    private async getSelectedContentParamters(selectedLibs:StdIdWithNameStructure[],errors:string[],warnings:string[],startIndex:number)
    {
        await this.getItemNumbersInformations();
        for( let index = startIndex; index < selectedLibs.length; ++index)
        {
            const isLastLibToLoad = ( (index) == selectedLibs.length-1) ? true : false;
            const curselLibId   = selectedLibs[index].id;
            const curselLibName = selectedLibs[index].name;

        
            await this.getContentParamterByLibId(curselLibId,curselLibName, this._dbFilterKey,
                                                 isLastLibToLoad,errors,warnings,index).then( async (libData:ICLibDataExtStruct[]) =>  {

                if (libData !== null)
                {
                    var others = this._currentGridData.filter(function(item) { return item.Title === 'Others'; })
                    var notOthers = this._currentGridData.filter(function(item) { return item.Title !== 'Others'; })
                    
                    if (this._currentGridData === null)
                        this._currentGridData = [...libData];
                    else
                        this._currentGridData = [...notOthers,...libData,...others];
                }
                
                if (isLastLibToLoad)
                {

                    this._spinner.show(false);
                    this._spinner.show(true,"loading data...please wait");
                    let libData:ICLibDataExtStruct[] = [];
                    
                            for (let currInfo of this._itemNumberInfos)   
                            {
                                
                                if (currInfo.libraryId == null) 
                                {
                                    const item:ICLibDataExtStruct = {  
                                        LibraryId:         currInfo.libraryId,  
                                        Title:             "Others",  
                                        Filename:          currInfo.filename,
                                        CurrentRevisionId: currInfo.currentRevisionId,
                                        RepositoryFileId:  currInfo.repositoryFileId,
                                        Name:              "", 
                                        ProdDesc:          "",
                                        RSenCProdDesc:     "",
                                        Typename:          "",
                                        Value:             currInfo.value, 
                                        RevitYear:         currInfo.revitYear,
                                        Source:            "",
                                        NumberInUnifi:     currInfo.isInUnifi,
                                        NumberInBIMCad:    currInfo.isInBimCad,
                                        NumberInPortfolio: currInfo.isInPortfolio                                      
                                    }  
                
                                    libData.push(item);  
                                }
                            }
            
                            if (libData !== null)
                            {
                                var others = this._currentGridData.filter(function(item) { return item.Title === 'Others'; })
                                var notOthers = this._currentGridData.filter(function(item) { return item.Title !== 'Others'; })
                                
                                if (this._currentGridData === null)
                                    this._currentGridData = [...libData];
                                else
                                    this._currentGridData = [...notOthers,...libData,...others];
                            }
                            this._spinner.show(false);
                        

                    const libName = ( selectedLibs.length > 1 ) ? "multi-selection" : curselLibName;
                    this.after_getLibraryByLibId(libName);
                }
            })
            .catch((error) => { 
                const errInfo = "[Error]: " + error + "  " + ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_CONTENT_PARAMETER);    
                console.log( errInfo );
                errors.push( errInfo);
                this._spinner.show(false);
            });               
        }

       
    }

    
    //=================================================================================================================

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }



    async getContentParamterByLibId(libId:string,libName:string,dbkeyName:string,isLastLibToLoad:boolean,
                                    errors:string[],warnings:string[],rowIndex:number) : Promise<ICLibDataExtStruct[]> 
    {
        this._spinner.show(false);
        this._spinner.show(true,"loading: " + "( " + (rowIndex + 1)  + " )  " + libName);
        console.log("try to load " + libName);
       
        const libData:ICLibDataExtStruct[]=[];

        const apiClient:Client = this.props.parentPanel.getApiClient();

        await apiClient.getAllContentParametersFromStorage(libId).then( (content:ContentParameterDto[]) =>  {
           
            for (let item of content)   // IContentParameter[]
            {
                const groupedByCohesion = this.groupBy(item.parameters, p => p.cohesion);

            
                groupedByCohesion.forEach(
                    contentParameterArray => 
                    {
                        let typeName     = contentParameterArray[0].typeName;

                        let itemNumber = contentParameterArray.find(e => e.name.toLowerCase() === UnifiCore.ITEM_NUMBER)?.value;

                       


                        let description = contentParameterArray.find(e => e.name.toLowerCase() === UnifiCore.PRODUCT_DESCRIPTION)?.value;
                        let rsenDescription = contentParameterArray.find(e => e.name.toLowerCase() === UnifiCore.RSEN_PRODUCT_DESCRIPTION)?.value;

                        let libraryId         = contentParameterArray[0].libraryId;
                        let title             = contentParameterArray[0].title;
                        let  filename          = contentParameterArray[0].filename;
                        let currentRevisionId = contentParameterArray[0].currentRevisionId;
                        let repositoryFileId  = contentParameterArray[0].repositoryFileId;
                        let revitYear         = contentParameterArray[0].revitYear;
                        let itemSource        = contentParameterArray[0].source; 


                    

                        let isInUnifi = false;
                        let isInBimCad = false;
                        let isPortfolioItemNumber = false;
                        // =======================================================================================
                        // for current libraryId and current item-number ( value )
                        // get info whether is Unifi, BimCad or Portfolio.
                        //========================================================================================
                        if (this._itemNumberInfos.length > 0)
                        {
                            for (let currInfo of this._itemNumberInfos)   
                            {
                                if (currInfo.libraryId == libId && currInfo.value == itemNumber) 
                                {
                                    isInUnifi            = currInfo.isInUnifi;
                                    isInBimCad            = currInfo.isInBimCad;
                                    isPortfolioItemNumber = currInfo.isInPortfolio;
                                    break;
                                }
                            }
                        }
                        //======================================================================================
                        // check if we are in mode, where we dont accept duplicate item-numbers
                        //======================================================================================
                        let skip = false;
                        if (this.state.acceptDuplicates === false) {
                            if (libData.some(e => e.Value === itemNumber)) {
                                // Duplicate!
                                skip =true;
                            }
                            
                        }
               
                        if (skip == false) 
                        {
                            const cxx:ICLibDataExtStruct = {  
                                LibraryId:         libraryId,  
                                Title:             title,  
                                Filename:          filename,
                                CurrentRevisionId: currentRevisionId,
                                RepositoryFileId:  repositoryFileId,
                                Name:              "", 
                                ProdDesc:          description,
                                RSenCProdDesc:     rsenDescription,
                                Typename:          typeName,
                                Value:             itemNumber, 
                                RevitYear:         revitYear,
                                Source:            SourceNames[ itemSource ],
                                NumberInUnifi:     isInUnifi,
                                NumberInBIMCad:    isInBimCad,
                                NumberInPortfolio: isPortfolioItemNumber                                      
                            };    

                    
                            libData.push(cxx); 

                        }
                    }
                );               
            }


            if (!ExtensionUtil.isValidContent( content ))            
            {
                let infoText    = ExtensionUtil.getErrorText(ExtensionsError.WARNING_READ_CONTENT_PARAMETER);
                const info      = infoText.replace("%", libName );
                console.log( info );
                warnings.push(info);
            }
            else
            {
                console.log("successful load of  " + libName);    
            }
            this._spinner.show(false);
        })
        .catch((error) => { 
            const errInfo = "[Error]: name: " + libName + "  id: " + libId + "  " + ExtensionUtil.getErrorText(ExtensionsError.ERROR_READ_CONTENT_PARAMETER);    
            console.log( errInfo );
            errors.push( errInfo);
            this.saveErrorInfoToFile(errInfo,libName);
            if (isLastLibToLoad)
            {
                this.after_getLibraryByLibId(libName);
            }
            return null;
        });
        return( libData );
    }

    //=================================================================================================================

    private saveErrorInfoToFile(errInfo:string,libName:string)
    {
        const now_as_number  = Date.now();
        const uniquefileName =  now_as_number +  "-" + libName + "_unifi_errinfo.err";

        HtmlUtils.saveTextFile(uniquefileName,errInfo);
    }

    //=================================================================================================================

    private onLibrarySelectionChanged = (event, currentSelectedOptions) => {

        let nSelectedLibs = 0;

        if (ExtensionUtil.isValidContent( currentSelectedOptions ))
        {
            this._selectedOptions = [...currentSelectedOptions];
            nSelectedLibs = this._selectedOptions.length;
        }
        else
        {
            this._selectedOptions = [];
        }
        
        if (!this._guiElementesInitialized)
        {
            this.setButtonTooltips();
        }

        this.setState({ canLoad:  ((nSelectedLibs > 0) ? true : false) } );
    }

    //=================================================================================================================

    private onExportGridData = (e) => {

        let bCheck = false;
        if ( this._LibraryContentGrid != null)
        {
            const rows = this._LibraryContentGrid.getRows('active');

            if (ExtensionUtil.isValidContent( rows ))
            {
                this._LibraryContentGrid.destroy();
                this.setupCurrentLibraryContensGrid( this._currentGridData, true); // we need to have an grid with all groups expanded
                const libName        = this.state.curselLibraryName;
                const now_as_number  = Date.now();
                const uniquefileName = libName + "-" + now_as_number + "_unifi_data.csv";
                this._LibraryContentGrid.download("csv", uniquefileName,  {delimiter: this._csvFileDelimiter }); 
                bCheck=true;
                const infoRecordsSaved = ExtensionUtil.getInfoText( ExtensionsInfo.RECORDS_SUCCESSFUL_SAVED );
                const infoDelimiter    = ExtensionUtil.getInfoText( ExtensionsInfo.CAPTION_DELIMITER );
                const infoFileName     = ExtensionUtil.getInfoText( ExtensionsInfo.CAPTION_FILENAME );
                const info = " " + rows.length + " " + infoRecordsSaved + "\n" + infoFileName + ":  " + uniquefileName +  "\n" +  infoDelimiter + ":  " + this._csvFileDelimiterName;
                //alert(info);
                this.setState( {showAlert: true, alertType:  SeverityType.SUCCESS,  alertDuration: 6000,  alertMessage: info });
            }
        }
        if (!bCheck) {
            alert( ExtensionUtil.getInfoText(ExtensionsInfo.NO_DATA_FOUND));
        }
    }

    //=================================================================================================================

    private onResetGridData(e)
    {
        // show all columns again !
        if ( this._LibraryContentGrid != null)
        {
            if (ExtensionUtil.isValidContent(this._hiddenColumns))
            {
                for (let currrentColumnName of this._hiddenColumns)
                {
                    this._LibraryContentGrid.showColumn( currrentColumnName );         
                } 
                this._hiddenColumns = [];
                HtmlUtils.enableButton("id-button-reset",false);
            }
        }
    }

    //=================================================================================================================

    private onExpandAllGridGroups(e)
    {
        var groups = this._LibraryContentGrid.getGroups();
        groups.forEach(function(currGroup) {

            if (!currGroup._group.visible) //  visible == false means currently collapsed
            {
                currGroup.show();
            }
        });   
        this.setState({ mouseX: 0 });
        this.setState({ mouseY: 0 });
    }

    //=================================================================================================================

    private onCollapseAllGridGroups(e)
    {
        var groups = this._LibraryContentGrid.getGroups();

        groups.forEach(function(currGroup) {

            if (currGroup._group.visible)  //  visible == true means currently expanded
            {
                currGroup.hide();
            }
        }); 
        this.setState({ mouseX: 0 });
        this.setState({ mouseY: 0 });
    }

    //=================================================================================================================

    private canToggleCurselGridGroup() : boolean
    {
        if (this._LibraryContentGrid != null)
        {
            var selectedRows = this._LibraryContentGrid.getSelectedRows(); //get array of currently selected row components.
            return (selectedRows.length == 1) ? true : false;
        }
        return false;
    }

    private canCollapseGridGroups() : boolean
    {
        if (this._LibraryContentGrid != null)
        {
            var groups = this._LibraryContentGrid.getGroups();
            if (groups !== null && groups.length > 0)
            {
                for ( let currGroup of groups)
                {
                    if (currGroup._group.visible)  //  visible == true means currently expanded
                    {
                        // at least one group is expanded
                        // so , yes we can Collpase
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private canExpandGridGroups() : boolean
    {
        if (this._LibraryContentGrid != null)
        {
            var groups = this._LibraryContentGrid.getGroups();
            if (groups !== null && groups.length > 0)
            {
                for ( let currGroup of groups)
                {
                    if (!currGroup._group.visible) //  visible == false means currently collapsed
                    {
                        // at least one group is collapsed
                        // so , yes we can Expand
                        return true;
                    }                    
                }
            }
        }
        return false;
    }

    //=================================================================================================================

    private onToggleCurselGridGroup(e)
    {
        let groupName:string  = null;
        var selectedRows = this._LibraryContentGrid.getSelectedRows(); //get array of currently selected row components.
        if (selectedRows.length == 1)
        {
            var selectedRow = selectedRows[0];
            groupName = selectedRow._row.data.Title;
            var groups = this._LibraryContentGrid.getGroups();
            groups.forEach(function(currGroup) {

                if (currGroup._group.key == groupName)  //  visible == true means currently expanded
                {
                    if (currGroup._group.visible)  //  visible == true means currently expanded                    
                        currGroup.hide();                
                    else
                        currGroup.show();
                }
            });    
        }
    }

    //=================================================================================================================

    private onAcceptDuplicatesChange = (event) => {

        this.setState( { acceptDuplicates: !this.state.acceptDuplicates });
        this.loadSelectedLibraries();
      };
    
    //=================================================================================================================

    public close()
    {
        this._spinner.show(false);
    }

    private onCloseAlertMessage = () => {

        this.setState( { showAlert: false } );
    };

    private onCloseMessageBox = () => {

        this.setState( { showMessageBox: false } );
    };

    private onCloseContextMenu = (event) => {

        this.setState({ showContextMenu: false });

        this.setState( { anchorEl: event.currentTarget });

    };


    private handleClick = (event) => {

        event.preventDefault();

        const xPos:number = event.clientX - 2;
        const yPos:number = event.clientY - 4;

        this.setState({ mouseX: xPos });
        this.setState({ mouseY: yPos });
    };
    
    private handleClose = () => {

        this.setState({ mouseX: 0 });
        this.setState({ mouseY: 0 });
    };



    //=================================================================================================================
 
    private renderLibrariesComboBox() 
    {
        const preSelected = [];
        preSelected.push("_swTest");

        const xstyles = {
            
            control: base => ({
              ...base,
              fontFamily: "Roboto"
            }),
            menu: base => ({
              ...base,
              fontFamily: "Roboto"
            })
          };

        return ( 
   
            <div className="container-fluid m-0" id="id-libraries-container">

                <div className="row m-2 p-2">

                    <div className="col-12-left">
                  
                        <div className="input-group">
                      
                            <ThemeProvider theme={mui_darkTheme}>
                            <Autocomplete
                                clearOnEscape
                                openOnFocus
                                multiple
                                id="id-unifi-libraries"
                                options = {this.state.libraries}    
                                disableCloseOnSelect
                                onChange={this.onLibrarySelectionChanged}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, state) => {

                                    const selectItemIndex = this._selectedOptions.findIndex( item => item.name === this._captionAll  );

                                    if (selectItemIndex > -1)
                                    {
                                        state.selected = true;
                                    }

                                    return (

                                        <React.Fragment>

                                            <Checkbox
                                                style={{color:'primary', marginRight: 8}}
                                                color ="default"
                                                icon            ={icon}
                                                checkedIcon     ={checkedIcon}
                                                checked         ={state.selected}
                                            />

                                            {option.name}
                                        </React.Fragment>
                                    );
                                }}

                                style={{ width: 920 }}

                                renderInput={params => (

                                    <TextField
                                        {...params}
                                            variant     ="outlined"
                                            label       ="Select Content-Storage-Library"
                                            // placeholder ="Favorites"
                                        />
                                    )}
                            />
                            </ThemeProvider>
                         

                            <span className="input-group-btn" id="id-button-goup-span">

                                <Tooltip title="if checked => duplicated item-numbers will be accepted">
                                    <FormControlLabel id="id-button-dublicates" control={<Checkbox checked={this.state.acceptDuplicates}  style ={{color: "#C0C0C0"}}  onChange={this.onAcceptDuplicatesChange}    name="acceptDuplicates" />}label="Duplicates"/>
                                </Tooltip>      

                                <button id="id-button-getdata"  onClick={ (e) => this.onLoadSelectedLibraries(e)}  disabled={!this.state.canLoad}  className="btn btn-secondary btn-getdata"><i className="fa fa-play" aria-hidden="true"></i></button>
                                <button id="id-button-export"   type="button"  onClick={ (e) => this.onExportGridData(e)}         disabled={!this.state.canExport}  className="btn btn-secondary btn-export"><i className="fas fa-cloud-download-alt" aria-hidden="true"></i></button>
                                <button id="id-button-reset"    style={{visibility: "hidden"}}  type="button"  onClick={ (e) => this.onResetGridData(e)} className="btn btn-secondary btn-reset"><i className="far fa-dot-circle" aria-hidden="true"></i></button>
                   

                            </span>

                        </div>        
                    </div>
                </div>
            </div>
        );

    }

  
    //=================================================================================================================
    
    render() {

        const canToggle   = this.canToggleCurselGridGroup();
        const canExpand   = this.canExpandGridGroups();
        const canCollapse = this.canCollapseGridGroups();

        let useSeverity:any         = DialogUtil.getSeverity(this.state.alertType);
        let useHorizontalOrigin:any = DialogUtil.getHorizontalOrigin(this.state.alertHOrigin);
        let useVerticalOrigin:any   = DialogUtil.getVerticalOrigin(this.state.alertVOrigin);

        let content_libraries = this.state.loadingLibraries ? <p><em>.</em></p> : this.renderLibrariesComboBox();

        return (
    
            <div>
  
                    <div>     
                        <ThemeProvider theme={g_darkThemeDialog}>
                        <Snackbar 
                            open                = { this.state.showAlert } 
                            TransitionComponent = { this.state.alertTransition }
                            autoHideDuration    = { this.state.alertDuration } 
                            anchorOrigin={{
                                vertical:   useVerticalOrigin,
                                horizontal: useHorizontalOrigin,
                            }}   
                            onClose = { this.onCloseAlertMessage }>

                            <Alert 
                                onClose  = { this.onCloseAlertMessage } 
                                // variant  ="filled"
                                // variant  ="outlined"
                                severity = { useSeverity }>
                                { this.state.alertMessage }
                            </Alert>

                        </Snackbar>
                        </ThemeProvider>
                    </div>

                    <div>     
                        <ThemeProvider theme={g_darkThemeDialog}>
                        <Dialog
                            open   =  { this.state.showMessageBox }
                            TransitionComponent =   { Transition }
                            keepMounted
                            onClose             =   {  this.onCloseMessageBox }
                            aria-labelledby     =   "alert-dialog-slide-title"
                            aria-describedby    =   "alert-dialog-slide-description">

                            <DialogTitle id="alert-dialog-slide-title">{  this.state.dlgTitle }</DialogTitle>
                            
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    <Typography display="block" color="textPrimary" >
                                        { this.state.dlgContentText }
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={ this.onCloseMessageBox } color="primary">Close</Button>
                            </DialogActions>

                        </Dialog>
                        </ThemeProvider>
                    </div> 

                    <div>                                
                        <Menu
                            keepMounted
                            open={this.state.mouseY !== 0}
                            onClose={this.handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                            this.state.mouseY !== 0 && this.state.mouseX !== 0
                                ? { top: this.state.mouseY, left: this.state.mouseX }
                                : undefined
                            }
                        >
                            <MenuItem disabled={!canExpand}    onClick={this.onExpandAllGridGroups}>Expand all collapsed Groups</MenuItem>
                            <MenuItem disabled={!canCollapse}  onClick={this.onCollapseAllGridGroups}>Collapse all expanded Groups</MenuItem>
                            <MenuItem disabled={!canToggle}    onClick={this.onToggleCurselGridGroup}>Expand/Collapse Group of current selected Row</MenuItem>
                        </Menu>
                    </div>
          

                <div>
                    <div onContextMenu={this.handleClick} style={{ cursor: 'context-menu' }}>                        
                        {content_libraries}
                    </div>
                </div>
                
                <div className="some-space"></div>      

                <div className="container m-0 p-0  flex-column d-flex" id="id-spinnerContainer-unifi">
                    <div id="id-spinnerCaptionPanel-unifi"/>
                    <div className="col-xs-6" id="id-spinnerPanel-unifi"/>
                </div>
             
                <div className="unifi-grid-container">
                    <div className="unifi-grid-content">
                        <div id="currentLibContentTable"/>
                     </div>
                </div>
          </div>

        );
    }

    //=================================================================================================================
}



