import { ExtensionsError, ExtensionUtil } from "../../utils/utils";
import Config from "../../../../Config";
import { UnifiExtension } from "../../UnifiExtension/UnifiExtension";
import { DbInitExtension } from "../../DbInitExtension/DbInitExtension";
import { PlayerExtension } from "../../PlayerExtension/PlayerExtension";
import { ModelLoaderExtension } from "../ModelLoaderExtension";
import { BIM360DataManagerExtension } from "../../BIM360DataManagerExtension/BIM360DataManagerExtension";
import { AssemblyExtension } from "../../AssemblyExtension/AssemblyExtension";
import { SearchExtension } from "../../SearchExtension/SearchExtension";
import { ChartExtension } from "../../ChartExtension/ChartExtension";
import { DefaultExtension } from "../../DefaultExtension/DefaultExtension";
import { ItemNumberCrawlerExtension } from "../../ItemNumberCrawlerExtension/ItemNumberCrawlerExtension";
import { ModelConfiguratorExtension } from "../../ModelConfiguratorExtension/ModelConfiguratorExtension";
import { PropertyExtension } from "../../PropertyExtension/PropertyExtension";
import { TestExtension } from "../../TestExtension/TestExtension";
import { LEAExtension } from "../../LEAExtension/LEAExtension";
import { ScreenShotExtension } from "../../ScreenShotExtension/ScreenShotExtension";
import { ViewerSettingsExtension } from "../../ViewerSettingsExtension/ViewerSettingsExtension";
import { BimCadExtension } from "../../BimCadExtension/BimCadExtension";

export default class ModelLoader {
  //=================================================================================================================
  private static newCreatedViewer = null;
  //=================================================================================================================

  public static xLoad(viewer, docId: string, forgeAccessToken: string) {
    if (viewer.model) {
      viewer.tearDown();
      viewer.setUp(viewer.config);
    }
    const docId_with_urn = "urn:" + docId;
    Autodesk.Viewing.Document.load(
      docId_with_urn,
      ModelLoader.onLoadWithDocIdSuccess,
      ModelLoader.onLoadWithDocIdFailure
    );
  }

  //=================================================================================================================

  public static loadModelFromWeb(
    viewer: any,
    docId: string,
    forgeAccessToken: string
  ) {
    if (!ExtensionUtil.isValidContent(docId)) {
      alert(
        ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_LOADMODEL_INVALID_DOCUMENT_ID
        )
      );
      return;
    }
    if (!ExtensionUtil.isValidContent(forgeAccessToken)) {
      alert(
        ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_LOADMODEL_INVALID_ACCESS_TOKEN
        )
      );
      return;
    }

    const viewerOptions = {
      env: "AutodeskProduction",
      accessToken: forgeAccessToken,
      //  env: 'MD20ProdUS',
      //  api: 'D3S', // for models uploaded to EMEA change this option to 'derivativeV2_EU'
    };

    viewer.tearDown(); // release viewer resources...before we create a new viewer !!

    Autodesk.Viewing.Initializer(viewerOptions, () => {
      let container: HTMLElement | null;
      let viewer: Autodesk.Viewing.GuiViewer3D;

      container = document.getElementById("forgeviewer");

      if (container !== null) {
        const docId_with_urn = "urn:" + docId;

        viewer = new Autodesk.Viewing.GuiViewer3D(container);

        //viewer = new Autodesk.Viewing.GuiViewer3D(container , {extensions:["Test.TestExtension"]});

        ModelLoader.newCreatedViewer = viewer;

        ModelLoader.loadExtensions(viewer, docId);

        viewer.start();

        Autodesk.Viewing.Document.load(
          docId_with_urn,
          ModelLoader.onLoadWithDocIdSuccess,
          ModelLoader.onLoadWithDocIdFailure
        );
      } else {
        alert(
          ExtensionUtil.getErrorText(
            ExtensionsError.ERROR_LOADMODEL_INVALID_CONTAINER_ELEMENT
          )
        );
      }
    });
  }

  //=================================================================================================================

  private static loadExtensions(viewer: any, modelURN: string) {
    //   TestExtension.loadExtension(viewer);
    UnifiExtension.loadExtension(viewer);
    PlayerExtension.loadExtension(viewer);
    SearchExtension.loadExtension(viewer);
    ScreenShotExtension.loadExtension(viewer);

    ChartExtension.loadExtension(viewer);
    DbInitExtension.loadExtension(viewer);
    AssemblyExtension.loadExtension(viewer);
    //    DefaultExtension.loadExtension(viewer);

    DefaultExtension.loadExtension(viewer);
    ModelLoaderExtension.loadExtension(viewer, modelURN);

    //      LEAExtension.loadExtension(viewer);
    BIM360DataManagerExtension.loadExtension(viewer);

    // ItemNumberCrawlerExtension.loadExtension(viewer);
    ModelConfiguratorExtension.loadExtension(viewer);

    ViewerSettingsExtension.loadExtension(viewer);

    BimCadExtension.loadExtension(viewer);

    PropertyExtension.loadExtension(viewer);

    viewer.loadExtension("Autodesk.VisualClusters", {});
  }

  //=================================================================================================================

  public static loadModelLocal(viewer: any, modelUrl: string): boolean {
    let bRet = false;

    if (ExtensionUtil.isContentNotNull(modelUrl)) {
      const models = viewer.impl.modelQueue().getModels();

      if (ExtensionUtil.isValidContent(models)) {
        viewer.impl.removeModel(models[0]); //.unloadModel( models[0] );   // // has at least one model
      } else {
        // there is nothing to unload
      }
      viewer.loadModel(
        modelUrl,
        ModelLoader.onDocumentLoadSuccess,
        ModelLoader.onDocumentLoadFailure
      );
      bRet = true;
    } else {
      alert(
        ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_LOADMODEL_INVALID_MODELURL
        )
      );
    }
    return bRet;
  }

  //=================================================================================================================

  public static onDocumentLoadSuccess(viewerDocument) {
    alert("could load sucessfully");
    // viewerDocument is an instance of Autodesk.Viewing.Document
  }

  //=================================================================================================================

  public static onDocumentLoadFailure() {
    // even get called when some font data (italic.woff) cannot be loaded
    //alert('Failure loading Document');
  }

  //=================================================================================================================

  public static onLoadWithDocIdFailure() {
    alert(ExtensionUtil.getErrorText(ExtensionsError.ERROR_LOADMODEL_FAILED));
  }

  //=================================================================================================================

  public static onLoadWithDocIdSuccess(viewerDocument) {
    const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    if (defaultModel !== null) {
      ModelLoader.newCreatedViewer.loadDocumentNode(
        viewerDocument,
        defaultModel
      );
      // to choose a different model, retrieve the list of all models and have custom code to decide which one to load:
      // var viewables = viewerDocument.getRoot().search({'type':'geometry'});
      // viewer.loadDocumentNode(viewerDocument, viewables[0]);
    } else {
      alert(
        ExtensionUtil.getErrorText(
          ExtensionsError.ERROR_LOADMODEL_NO_DEFAULTMODEL
        )
      );
    }
  }

  //=================================================================================================================
}
