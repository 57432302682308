import './css/DbInitExtension.css';
import {ExtensionUtil} from '../utils/utils'
import  DbInitExtensionBusinessLogic  from './DbInitExtensionBusinessLogic';
import  {CircleSpinner}  from '../utils/GuiComponents/CircleSpinner';
import ReactDOM from 'react-dom';
import React from 'react';
import DbInitGUIPanel from "./components/DbInitGUIPanel";
import Axios from 'axios';
import { Client } from '../../../client';

export default class DbInitExtensionPanel extends Autodesk.Viewing.UI.DockingPanel 
{
    //=================================================================================================================
    protected _wrapper   :HTMLDivElement;
    protected  _DOMContent:any;
    protected  _reactNode:any;
    protected _spinnerSize:number   = 30;
    protected _spinnerColor:string  = "#FF0000";
    protected _initialXPos = "80";
    protected _initialYPos = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    private   _guiPanel:DbInitGUIPanel = null;
    private   _getAssembliesUrl:string = null;
    private   _axiosInstance = null;
    private   _axiosBaseUrl  = null;
    private   _apiClient     = null;
    //=================================================================================================================
    _dbInitBusinessLogic:DbInitExtensionBusinessLogic = null;
    //=================================================================================================================

    constructor(viewer:any, container:any, id:any, title:any, options:any)
    {
        super(viewer.container, id, title, options);
       
        //Autodesk.Viewing.UI.DockingPanel.call(this, container, id, title, options);
        
        if (ExtensionUtil.isValidContent(options))
        {
            if (ExtensionUtil.isContentNotNull(options.init_options.GET_ASSEMBLIES_URL))
                this._getAssembliesUrl = options.init_options.GET_ASSEMBLIES_URL;

            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_SIZE))
            {
                this._spinnerSize = options.init_options.SPINNER_SIZE;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_COLOR))
            {
                this._spinnerColor = options.init_options.SPINNER_COLOR;
            }   
            if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
            {
                const posString = options.init_options.PANEL_INITIAL_POS;
                const xyCoords = posString.split(',');
                if (xyCoords.length === 4)
                {
                    this._initialXPos   = xyCoords[0];
                    this._initialYPos   = xyCoords[1];
                    this._initialWidth  = xyCoords[2];
                    this._initialHeight = xyCoords[3];
                }
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.BASE_URL))
            {
                this._axiosBaseUrl = options.init_options.BASE_URL;
            }
        }

        //================================================================================

        this._dbInitBusinessLogic = new DbInitExtensionBusinessLogic(this,viewer, options);

        this.createAxiosInstance();

        //================================================================================

       this.container.classList.add("docking-panel-container-solid-color-a");
       this.container.style.width       =  this._initialWidth +"px";  
       this.container.style.height      =  this._initialHeight +"px"; 
       this.container.style.minWidth    = "200px";
       this.container.style.minHeight   = "50px";
       this.container.style.resize      = "none";
       this.container.style.position    = "absolute";
       this.container.style.left        = this._initialXPos + "px";
       this.container.style.top         = this._initialYPos + "px";

        this.container.classList.add('react-dbinit-docking-panel')
        //=================================================================================
        this._DOMContent = document.createElement('div')
        this._DOMContent.setAttribute("id", "dbinit-dp");
        this._DOMContent.className = 'content'
        //=================================================================================
        this._wrapper = document.createElement('div');
        this._wrapper.setAttribute("class", "dbinitcontainer flex-column d-flex p-0 m-0");
        this._wrapper.appendChild(this._DOMContent );
        //=================================================================================
        this.container.appendChild( this._wrapper ); 
        //=================================================================================
    }

    //=================================================================================================================

    public initialize() : void {

        // const scrollContainerOptions = { left: false, heightAdjustment: 45, marginTop: 0 };
        // const scrollContainer = this.createScrollContainer(scrollContainerOptions);

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);
  
        this.initializeMoveHandlers(this.title);
  
        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);
    
        this.footer = this.createFooter();
        this.container.appendChild(this.footer);

    }

    //===================================================================================================================
  
    public getSpinnerColor() : string
    {
        return  this._spinnerColor;
    }
    public getSpinnerSize() : number
    {
        return  this._spinnerSize;
    }
    public setGUIPanel(guiPanel:DbInitGUIPanel)
    {
        this._guiPanel = guiPanel;
    }    
    public getModelIdent()
    {
        return this._dbInitBusinessLogic.getModelIdent();
    }
    public getDbInitBusinessLogicObect()
    {
        return this._dbInitBusinessLogic;
    }    
    public getAssembliesUrl()
    {
        return this._getAssembliesUrl;
    }

    //=================================================================================================================

    setVisible (show) 
    {
        if ( this._guiPanel)
        {
            //this._guiPanel.close();
        }

        super.setVisible(show)

        if (show) 
        {
            this._reactNode = ReactDOM.render(<DbInitGUIPanel  parentPanel = {this} />, this._DOMContent)
        }
        else if (this._reactNode)
        {
            ReactDOM.unmountComponentAtNode(this._DOMContent)
            this._reactNode = null  
        }
    }

    //=================================================================================================================

    private createAxiosInstance()
    {
        if (this._axiosInstance == null)
        {
            this._axiosInstance = Axios.create({  baseURL: this._axiosBaseUrl });
            if (this._axiosInstance != null)
            {
                this._apiClient = new Client( this._axiosBaseUrl, this._axiosInstance );
            }
            else
            {
                alert("axios error");
            }
        }
    }

    //===================================================================================================================

    public getApiClient()
    {
        return  this._apiClient;
    }

    //===================================================================================================================
}