import './css/PlayerExtension.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {ExtensionUtil,FilterTypes,NavigationType,PLAYER_ExtensionText,PLAYER_SelectMode,PLAYER_ShowMode} from '../utils/utils'
import  PlayerGUIPanel  from './PlayerGUIPanel';
import BaseAnimationTimer from "../utils/BaseAnimationTimer";
import {ANIMTIMER_State} from "../utils/BaseAnimationTimer";
import ViewerToolkit, { IPropertiesResult } from '../utils/ViewerToolkit'
import {ModelDataTypes} from '../utils/ViewerToolkit'
import Axios from 'axios';
import { Client } from '../../../client';
import BasketGUIPanel, { BasketDataStruct } from "./BasketGUIPanel";
import { TransferWithinAStationTwoTone } from '@material-ui/icons';
import { HtmlUtils } from '../utils/HtmlUtils';
import SpinnerCore from '../utils/GuiComponents/SpinnerCore';
import DownloadRevitFile from './RevitFileUtil';

declare var THREE: any;


enum ANIM_DIRECTION
{
    FORWARD  = 1,
    BACKWARD = 2
};


export default class PlayerExtensionPanel extends Autodesk.Viewing.UI.DockingPanel 
{
    //=================================================================================================================
    protected _initialXPos   = "80";
    protected _initialYPos   = "140";
    protected _initialWidth  = "500";
    protected _initialHeight = "400";
    //=================================================================================================================
    private   _DOMContent:HTMLDivElement;
    private   _reactNode:any;
    private   _wrapper:any;
    //=================================================================================================================
    private   _axiosInstance = null;
    private   _axiosBaseUrl  = null;
    private   _apiClient     = null;
    //=================================================================================================================
    private _currentAnimFrame:number=0.0;
    private _animLastTime:number=0.0;
    private _useStepAnimation = true;
    private  _stepAnimTimer:BaseAnimationTimer;
    private  _animInitialDuration:number  = 1000;
    private  _animStepMaxDurationBackward:number  = 12000;
    private  _animStepMaxDurationForward:number   = 12000;
    private  _animStepMinDuration:number = 4000;
    private  _currentAnimStepDuration:number = 0.0;
    private  _rotAnim360MaxDurationForward  = 12000.0;
    private  _rotAnim360MaxDurationBackward = 12000.0;
    private  _rotAnim360MinDuration         = 4000.0;
    private  _rotAnimMaxFPS = 25;
    private  _currentRotAnim360RotDuration=0.0;
    protected _viewer = null;
    private   _cursel_dbids_array:Array<number> = null;
    private   _cursel_dbids_array_index_pos = -1;
    private  _seconds_pivot = null;
    private  _seconds_helper = null;
    private  _pin_Id:number = 0;
    private  _instanceTree = null;
    private _rotateAnimRequestId = 0;
    //=================================================================================================================
    private _searchPropertyName:string = "Manufacturer";
    private _searchDisplayValue:string = "Hilti";
    private _writeLogFile=false;
    private  _guiPanel:PlayerGUIPanel = null;
    private  _parent=null;
    private _bShowWithdbId=false;
    private _basketPanelWidth  = 280;
    private _isBasketPanelVisible=false;
    private _itemNumberLookupTable = null;
    private _jumpStep = 5;
    private _getDataFromPropertySearch = false;
    private _stepAnimDirection:ANIM_DIRECTION = ANIM_DIRECTION.FORWARD;
    private _rotAnimDirection:ANIM_DIRECTION  = ANIM_DIRECTION.FORWARD;
    public  _basketPanel = null;
    private _revitFileUtil=null;
    private _ctrlKeyON = false;
    private _stopRotateAnimNow = false;
    private _spinnerColor:string  = "#FF0000";
    private _spinnerSize:number   = 30;
    //=================================================================================================================

    constructor(viewer:any, container:any, id:any, title:any, options:any, parent:any)  
    {
        super(viewer.container, id, title, options);

        //=====================================================================
        this._viewer = viewer;
        this._parent = parent;
        //=====================================================================
        this._itemNumberLookupTable = new Array<BasketDataStruct>();

        this._viewer.addEventListener( Autodesk.Viewing.MODEL_REMOVED_EVENT,  this.onxViewerModelRemoved);

        //=====================================================================
        this._basketPanel = new BasketGUIPanel();
        //=====================================================================

        if (ExtensionUtil.isContentNotNull(options.init_options.PANEL_INITIAL_POS))
        {
            const posString = options.init_options.PANEL_INITIAL_POS;
            const xyCoords = posString.split(',');
            if (xyCoords.length === 4)
            {
              this._initialXPos   = xyCoords[0];
              this._initialYPos   = xyCoords[1];
              this._initialWidth  = xyCoords[2];
              this._initialHeight = xyCoords[3];
            }
        }   
        if (ExtensionUtil.isContentNotNull(options.init_options.BASKET_GRID_SIZE))
        {
            const sizeString = options.init_options.BASKET_GRID_SIZE;
            const wh_size    = sizeString.split(',');
            if (wh_size.length === 2)
            {
              this._basketPanelWidth  = wh_size[0];
            //  this._basketPanelHeight = wh_size[1];
            }
        }   
        if (ExtensionUtil.isContentNotNull(options))
        {
            if (ExtensionUtil.isValidContent(options.init_options.SEARCH_PROPERTY_NAME)  ) 
            {
                if (ExtensionUtil.isValidContent(options.init_options.SEARCH_DISPLAY_VALUE))
                {
                    this._searchPropertyName =  options.init_options.SEARCH_PROPERTY_NAME;  
                    this._searchDisplayValue =  options.init_options.SEARCH_DISPLAY_VALUE;  
                    this._getDataFromPropertySearch = true;
                }       
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.WRITE_LOG_FILE))
            {
                const value = parseInt(options.init_options.WRITE_LOG_FILE); 
                if (value > 0)
                    this._writeLogFile = true;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.STEP_ANIM_MAX_DURATION_FORWARD))
            {
                const value = parseInt(options.init_options.STEP_ANIM_MAX_DURATION_FORWARD); 
                if (value >= 1000 && value <= 30000)
                    this._animStepMaxDurationForward = value;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.STEP_ANIM_MAX_DURATION_BACKWARD))
            {
                const value = parseInt(options.init_options.STEP_ANIM_MAX_DURATION_BACKWARD); 
                if (value >= 1000 && value <= 30000)
                    this._animStepMaxDurationBackward = value;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.STEP_ANIM_MIN_DURATION))
            {
                const value = parseInt(options.init_options.STEP_ANIM_MIN_DURATION); 
                if (value >= 1000 && value <= 10000)
                    this._animStepMinDuration = value;
            }
            if (ExtensionUtil.isContentNotNull(options.init_options.STEPANIM_INITIAL_DURATION))
            {
                const value = parseInt(options.init_options.STEPANIM_INITIAL_DURATION); 
                if (value >= 2000 && value <= 50000)
                    this._animInitialDuration = value;
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.ROTANIM_360_DEGREES_ROTATION_FORWARD))
            {
                const value = parseInt(options.init_options.ROTANIM_360_DEGREES_ROTATION_FORWARD); 
                if (value >= 2000 && value <= 30000)
                    this._animStepMaxDurationForward = value;
            }    
            if (ExtensionUtil.isContentNotNull(options.init_options.ROTANIM_360_DEGREES_ROTATION_BACKWARD))
            {
                const value = parseInt(options.init_options.ROTANIM_360_DEGREES_ROTATION_BACKWARD); 
                if (value >= 2000 && value <= 30000)
                    this._animStepMaxDurationBackward = value;
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.ROTANIM_360_DEGREES_MIN_ROTATION))
            {
                const value = parseInt(options.init_options.ROTANIM_360_DEGREES_MIN_ROTATION); 
                if (value >= 2000 && value <= 30000)
                    this._rotAnim360MinDuration = value;
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.ROTANIM_MAX_FPS))
            {
                const value = parseInt(options.init_options.ROTANIM_MAX_FPS); 
                if (value >= 10 && value <= 35)
                    this._rotAnimMaxFPS = value;
            }  

            if (ExtensionUtil.isContentNotNull(options.init_options.SHOW_LABEL_WITH_DBID))
            {
                const value = parseInt(options.init_options.SHOW_LABEL_WITH_DBID); 
                if (value > 0)
                    this._bShowWithdbId = true;
            }  
            if (ExtensionUtil.isContentNotNull(options.init_options.BASKET_GRID_JUMP_STEP))
            {
                const value = parseInt(options.init_options.BASKET_GRID_JUMP_STEP); 
                if (value > 0 && value <= 20)
                    this._jumpStep = value;
            } 
            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_COLOR))
            {
                this._spinnerColor = options.init_options.SPINNER_COLOR;
            }   
            if (ExtensionUtil.isContentNotNull(options.init_options.SPINNER_SIZE))
            {
                const spinnerSize = options.init_options.SPINNER_SIZE;
                this._spinnerSize = parseInt(spinnerSize,10);
                if (this._spinnerSize > 120)
                    this._spinnerSize = 120;
                if (this._spinnerSize < 20)
                    this._spinnerSize = 20;
            }   
            if (ExtensionUtil.isContentNotNull(options.init_options.BASE_URL))
            {
                this._axiosBaseUrl = options.init_options.BASE_URL;
            }   
        }

       this.createAxiosInstance();

       this.container.classList.add("docking-panel-container-solid-color-a");

       this.container.style.width       =  this._initialWidth +"px";  
       this.container.style.height      =  this._initialHeight +"px"; 
       this.container.style.resize      = "none";
       this.container.style.minWidth    = this._initialWidth +"px"; 
       this.container.style.minHeight   = "50px";
       this.container.style.maxWidth    = this._initialWidth +"px"; 
       this.container.style.position    = "absolute";
       this.container.style.left        = this._initialXPos + "px";
       this.container.style.top         = this._initialYPos + "px";

        //=================================================================================
        this.container.classList.add('react-mg-docking-panel')
        //=================================================================================
        this._DOMContent = document.createElement('div')
        this._DOMContent.setAttribute("id", "testxxx");
        this._DOMContent.className = 'content'
        //=================================================================================
        this._wrapper = document.createElement('div');
        this._wrapper.setAttribute("class", "testcontainer flex-column d-flex p-0 m-0");
        this._wrapper.appendChild(this._DOMContent );
        //=================================================================================
        this.container.appendChild( this._wrapper ); 

        const that = this;  

        document.querySelector('#testxxx').addEventListener('keyup', function (e) {

            that._ctrlKeyON = false;
          //  alert("contril off");
          });

        document.querySelector('#testxxx').addEventListener('keydown', function (e) {

            const ev = e as KeyboardEvent;
            //if (ev.key === 's')            
            if (ev.ctrlKey == true)
            {
                that._ctrlKeyON = true;
            }    
        });
  
    }

    //===================================================================================================================

    public setGUIPanel(guiPanel:PlayerGUIPanel)
    {
        this._guiPanel = guiPanel;
        this._revitFileUtil = new DownloadRevitFile( this.getApiClient());

    }

    //===================================================================================================================

    public initialize() : void {

        this.title = this.createTitleBar(this.titleLabel || this.container.id);
        this.container.appendChild(this.title);
  
        //  click anywhere on the panel to move
        //this.initializeMoveHandlers(this.container);
        
        //  ONLY in the area of the titleBar the user can move the panel
        this.initializeMoveHandlers(this.title); 

        this.closer = this.createCloseButton();
        this.container.appendChild(this.closer);
    
  //      this.footer = this.createFooter();
  //      this.container.appendChild(this.footer);
      
      }
      
      //=================================================================================================================

      setVisible (show) 
      {
          super.setVisible(show)
    
          if (show) 
          {

            this._reactNode = ReactDOM.render(  <PlayerGUIPanel  parentPanel = {this} />, this._DOMContent)
             this.onStartClicked(null);
          }
          else if (this._reactNode)
          {
                this.onResetClicked(null);  // turn off all anims if running
                ReactDOM.unmountComponentAtNode(this._DOMContent)
                this._reactNode = null  
          }
      }

   //=================================================================================================================

    private createAxiosInstance()
    {
        if (this._axiosInstance == null)
        {
            this._axiosInstance = Axios.create({  baseURL: this._axiosBaseUrl });
    
            if (this._axiosInstance != null)
            {
                this._apiClient = new Client( this._axiosBaseUrl, this._axiosInstance );
            }
            else
            {
                alert("axios error");
            }
        }
    }

    //===================================================================================================================
    public getApiClient()
    {
        return  this._apiClient;
    }
    public isControlKeyOn()
    {
        return  this._ctrlKeyON;
    }
    public getSpinnerColor() : string
    {
        return  this._spinnerColor;
    }
    public getSpinnerSize() : number
    {
        return  this._spinnerSize;
    }
    //===================================================================================================================

    public onxViewerModelRemoved(event:any) : void 
    {
        //this._guiPanel.onViewerModelRemoved();
        alert(ExtensionUtil.getPlayerExtesionCaptionText(PLAYER_ExtensionText.CAPTION_MODEL_HAS_BEEN_REMOVED));
        //this.setVisible(false);
    }

    //===================================================================================================================

    private onNavigate(naviType,event)
    {
        if (event != null)   // user clicked on next Button...
        {           
            this.closeStepAnimation();  
        }

        let canNavigate = false;

        switch(naviType)
        {
            case NavigationType.FIRST:
                canNavigate = this.canDoFirst();
                break;    

            case NavigationType.LAST:
                canNavigate = this.canDoLast();
                break;    

            case NavigationType.NEXT:
                canNavigate = this.canDoNext(1);
                break;    

            case NavigationType.PREV:  
                canNavigate = this.canDoPrev(1);
                break;    

            case NavigationType.JUMP_FORWARD:
                canNavigate = this.canDoNext(this._jumpStep);
                break;                  

            case NavigationType.JUMP_BACKWARD:
                canNavigate = this.canDoPrev(this._jumpStep);
                break;

            default:
                break;            
        }
        if (canNavigate)
        {
            this.doPlay();     // show next object from selected object-ids
            
            if (this._rotateAnimRequestId && (!this._stopRotateAnimNow)) // if rotate-Anim is running
            {                              // initialize anim with the new object 
                // init and start rotate-anim for the next current (next) Node-object
                const nodeId = this._cursel_dbids_array[ this._cursel_dbids_array_index_pos];
                this.onInitRotateAnim(nodeId);
            }
        }
 
    }

   //=================================================================================================================

    public onNext(event:any) : void 
    {
        this.onNavigate(NavigationType.NEXT,event);
    }

   //=================================================================================================================

    public onPrev(event:any) : void 
    {
        this.onNavigate(NavigationType.PREV,event);
    }
  
    //=================================================================================================================

    public onJumpForward(event:any) : void 
    {
        this.onNavigate(NavigationType.JUMP_FORWARD,event);
    }

    //=================================================================================================================

    public onJumpBackward(event:any) : void 
    {
        this.onNavigate(NavigationType.JUMP_BACKWARD,event);        
    }

   //=================================================================================================================

    public onGotoFirst(event:any) : void
    {
        this.onNavigate(NavigationType.FIRST,event);       
    }

   //=================================================================================================================

   public onGotoLast(event:any) : void
   {
        this.onNavigate(NavigationType.LAST,event);       
   }

   //=================================================================================================================

   private canDoNext(step:number) : boolean
   {
       let bRet = false;

       if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   // must have length
       {
            if (this._guiPanel.getRandomState())            
            {
                const min_index_included = 0;
                const max_index_included = (this._cursel_dbids_array.length -1);
                this._cursel_dbids_array_index_pos = this.randomIndex(min_index_included,max_index_included);
            }
            else
            {
                if ( (this._cursel_dbids_array_index_pos + step)  < this._cursel_dbids_array.length) {
                    this._cursel_dbids_array_index_pos += step;
                }
                else {
                    this._cursel_dbids_array_index_pos = 0;
                }
     
            }
            bRet = true;
       }
       return bRet;
   }

   //=================================================================================================================

   private canDoPrev(step:number) : boolean
   {
       let bRet = false;

       if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   // must have length
       {
            if (this._guiPanel.getRandomState())            
            {
                const min_index_included = 0;
                const max_index_included = (this._cursel_dbids_array.length-1);
                this._cursel_dbids_array_index_pos = this.randomIndex(min_index_included,max_index_included);
            }
            else
            {
                if ( (this._cursel_dbids_array_index_pos - step)  >= 0) {
                    this._cursel_dbids_array_index_pos -= step;
                }
                else {
                    this._cursel_dbids_array_index_pos = this._cursel_dbids_array.length-1; 
                }           
            }
           bRet = true;
       }
       return bRet;
   }

   //=================================================================================================================

   private canDoLast() : boolean
   {
       let bRet = false;
       if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   // must have length
       {
            this._cursel_dbids_array_index_pos = this._cursel_dbids_array.length-1; 
            bRet = true;
       }
       return bRet;
   }

   //=================================================================================================================

   private canDoFirst() : boolean
   {
       let bRet = false;
       if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   // must have length
       {
            this._cursel_dbids_array_index_pos = 0; 
            bRet = true;
       }
       return bRet;
   }

   //=================================================================================================================

    private doPlay() : void
    {
        if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   // must have length
        {
            const new_selected_id      = this._cursel_dbids_array[ this._cursel_dbids_array_index_pos ];

            const singleIdArray:any[] = [];
            singleIdArray.push( new_selected_id);

            if (this._guiPanel.getAutoZoomState())
            {
                // #1  show not-selected parts in lighter ( 50% transparent) color  
                // this._viewer.isolate( singleIdArray );

                ViewerToolkit.isolateFull( this._viewer, null,  singleIdArray );

                this._viewer.fitToView(new_selected_id);   
            }
            else
            {
                this.clearViewerSelection(); 
                this._viewer.select( new_selected_id ); 

                this._viewer.isolate( this._cursel_dbids_array );
            }

            if (this._bShowWithdbId)
                this._guiPanel.setLabelText( "" + (this._cursel_dbids_array_index_pos+1) + " / " + this._cursel_dbids_array.length + "  dbid = " + new_selected_id );
            else
                this._guiPanel.setLabelText( "" + (this._cursel_dbids_array_index_pos+1) + " / " + this._cursel_dbids_array.length);
        }        
   }
   
   //=================================================================================================================

   private resetSelectionArray() : void
   {
       this._cursel_dbids_array            = [];  // clear array
       this._cursel_dbids_array_index_pos  = 0;   // set index to first indexpos
       this._guiPanel.setLabelText("")
   }

   //=================================================================================================================

   public onSetIsolationMode(bActive:boolean)
   {
        if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))
        {
            if (bActive)
            {
                this._viewer.clearSelection(); 

                this._viewer.isolate();   // reset isolation !!!
        
                ViewerToolkit.isolateFull( this._viewer, null,  null  );
        
                this._viewer.fitToView();

                const new_selected_id  = this._cursel_dbids_array[ this._cursel_dbids_array_index_pos ];
                const singleIdArray:any[] = [];
                singleIdArray.push( new_selected_id);

                
                ViewerToolkit.isolateFull( this._viewer, null,  singleIdArray );

                this._viewer.fitToView(new_selected_id);   
            }
            else
            {
                this._viewer.clearSelection(); 

                this._viewer.isolate();   // reset isolation !!!

                ViewerToolkit.isolateFull( this._viewer, null,  null );

                this._viewer.fitToView();

                const new_selected_id  = this._cursel_dbids_array[ this._cursel_dbids_array_index_pos ];
                const singleIdArray:any[] = [];
                singleIdArray.push( new_selected_id);

                this._viewer.select( new_selected_id ); 

                this._viewer.isolate( this._cursel_dbids_array );
            }
        }
   }

   //=================================================================================================================

   private turnOffAllAnimations()
   {
       this.closeStepAnimation();  
       //this.stopRotateAnim();
       if (this._rotateAnimRequestId)
       {
            window.cancelAnimationFrame(this._rotateAnimRequestId);
            this._rotateAnimRequestId = 0;
       }
   }

   //=================================================================================================================
  
   async onStartClicked(e:any) 
   {
        this.resetSelectionArray();

        const currentSelection:any[] = this._viewer.getSelection();
                
        if (this._getDataFromPropertySearch)
        {
            if (ExtensionUtil.isValidContent( currentSelection ))
            {
                this._cursel_dbids_array = currentSelection;
                this._viewer.clearSelection(); 
                this.checkValidLeafComponents();
                this.createItemNumbersLookupTable();
                this._guiPanel.init( null );
                this.doPlay();
            }
            else
            {
                this._viewer.clearSelection(); 
                let searchDisplayValue_ext = this._searchDisplayValue;
                let opcode = FilterTypes.NONE;

                if (searchDisplayValue_ext.startsWith( "{!=}"  ))
                {
                    searchDisplayValue_ext = searchDisplayValue_ext.substring(4); 
                    opcode = FilterTypes.STARTSWITH_EXCLUDE;
                }

                if (searchDisplayValue_ext.endsWith( "{*}" ))
                {
                    searchDisplayValue_ext = searchDisplayValue_ext.substring(0, searchDisplayValue_ext.length - 3); 
                    opcode = FilterTypes.ENDSWITH_INCLUDE;
                }

                if (searchDisplayValue_ext.startsWith( "{*}" ))
                {
                    searchDisplayValue_ext = searchDisplayValue_ext.substring(0, searchDisplayValue_ext.length - 3); 
                    opcode = FilterTypes.STARTSWITH_INCLUDE;
                }                

                if (ExtensionUtil.isContentNotNull( this._searchPropertyName) && opcode > 0)
                {
                    this._parent.loadModelDataTypes(ModelDataTypes.ALL_LEAF_NODES_2);

                    this._cursel_dbids_array = this._parent.getModelDataTypes();                    

                    await this.searchPropertyByKey( this._searchPropertyName, searchDisplayValue_ext, FilterTypes.STARTSWITH_EXCLUDE );

                    // remove all objects if the Name-Property startWith "Hilti_FieldPoints"
                    await this.removeAllHilti_FieldPointsFromList(  "Hilti_FieldPoints",  FilterTypes.STARTSWITH_EXCLUDE);  

                    this.checkValidLeafComponents();

                  
                    await this.createItemNumbersLookupTable();

                  //  this._guiPanel.init( null );
                  

                    this.doPlay();
                }
                else
                {
                    if ( ExtensionUtil.isContentNotNull( this._searchPropertyName) && ExtensionUtil.isContentNotNull(this._searchDisplayValue)  )
                    {
                        this._viewer.search( '"' + this._searchDisplayValue + '"', this.callback_getSubset,this.callback_SearchError);
                    }
                }
                this._viewer.showAll(); 
            }
        }
        else
        {
            let first_dbId_in_seletion = -1;

            if (ExtensionUtil.isValidContent( currentSelection ))
            {
                first_dbId_in_seletion = currentSelection[0];
            }

            this._parent.loadModelDataTypes(ModelDataTypes.ALL_LEAF_NODES_2);

            this._cursel_dbids_array = this._parent.getModelDataTypes();

            if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))
            {
                this._cursel_dbids_array.filter((e, i, a) => a.indexOf(e) !== i) // remove duplicate numbers

                if (first_dbId_in_seletion > 0)
                {
                    const index = this._cursel_dbids_array.indexOf(first_dbId_in_seletion); 

                    if (index >= 0)
                    {
                        this._cursel_dbids_array_index_pos  = index;
                    }
                }
            }
            this.doPlay();
        }
   }

    //=================================================================================================================

    async searchPropertyByKey(searchKey:string, propertySearchValue:string, opcode:number) 
    {
        const dbIds_found:number[] = [];

        for (let curr_dbId of  this._cursel_dbids_array)
        {
            await ViewerToolkit.processSearchPropertyByKey(this._viewer,searchKey,curr_dbId,propertySearchValue,opcode).then(function( result ) {

                if (result !== null)
                {
                    if (result == true)
                    {
                        dbIds_found.push(curr_dbId);
                    }
                }
                else
                {
                }
            })
            .catch((error) => {  alert("Error: 4564");  });    
        }
        this._cursel_dbids_array  = dbIds_found;
    }

    //=================================================================================================================

    async removeAllHilti_FieldPointsFromList(dontAcceptDisplayValue:string, opcode:number) 
    {
        const dbIds_found:number[] = [];

        for (let curr_dbId of  this._cursel_dbids_array)
        {
            await ViewerToolkit.searchAllPropertiesByRestriction(this._viewer,curr_dbId,dontAcceptDisplayValue,opcode).then(function( result ) {

                if (result !== null)
                {
                    if (result == true)
                    {
                        dbIds_found.push(curr_dbId);
                    }
                }
                else
                {
                }
            })
            .catch((error) => {  alert("Error: 4564");  });    
        }
        this._cursel_dbids_array  = dbIds_found;
    }

    //=================================================================================================================

   public startStepAnimation(animSpeedFactor:number) : void
   {
        if (this._useStepAnimation && ExtensionUtil.isValidContent( this._cursel_dbids_array))
        {
            if (animSpeedFactor == 0)
            {
                this.stopStepAnimation();
            }
            else
            {
                this._stepAnimDirection = ANIM_DIRECTION.FORWARD;
                if (animSpeedFactor < 0)
                {
                    this._stepAnimDirection = ANIM_DIRECTION.BACKWARD;
                }
                const speedValue = Math.abs( animSpeedFactor);
                const factor     =   1.001 -  ((speedValue - 0.0001)  / 100.0);

                if ( this._stepAnimDirection == ANIM_DIRECTION.FORWARD )
                {
                    const deltaDuration            = this._animStepMaxDurationForward - this._animStepMinDuration;
                    this._currentAnimStepDuration  = this._animStepMinDuration +  ( factor * deltaDuration);
                }
                else
                {
                    const deltaDuration            = this._animStepMaxDurationBackward - this._animStepMinDuration;
                    this._currentAnimStepDuration  = this._animStepMinDuration +  ( factor * deltaDuration);
                }

                if (this._stepAnimTimer == null)       // || this._stepAnimTimer.getState() == ANIMTIMER_State.ANIM_STATE_CREATED )
                {
                    this._stepAnimTimer = new BaseAnimationTimer(this._animInitialDuration,this._currentAnimStepDuration,
                                                                this._cursel_dbids_array.length);
                
                    this._stepAnimTimer.addEventListener('complete', this.onStepAnimTimerStep);
                    this._stepAnimTimer.start(this._currentAnimStepDuration);
                }
                else
                {
                    if ( this._stepAnimTimer.getState() == ANIMTIMER_State.ANIM_STATE_PAUSED )
                    {
                    //   alert("restart   index = " + this._cursel_dbids_array_index_pos + "  duration = "  + this._currentAnimStepDuration);
                        this._stepAnimTimer.reStart( this._cursel_dbids_array_index_pos, this._currentAnimStepDuration);
                        this._stepAnimTimer.addEventListener('complete', this.onStepAnimTimerStep);
                        this._stepAnimTimer.setStepIndex(this._cursel_dbids_array_index_pos + 1);
                    }
                }
            }
        }
    }

    //=================================================================================================================

    public stopStepAnimation() : void
    {
        if (this._stepAnimTimer)
        {
            this.closeStepAnimation();  
        }
    }

    //=================================================================================================================

    private closeStepAnimation() : void
    {
        if (this._stepAnimTimer)
        {
            this._stepAnimTimer.removeEventListener('complete', this.onStepAnimTimerStep);
            this._stepAnimTimer.close();
            this._stepAnimTimer=null;
        }
    }

   //=================================================================================================================

   private pauseStepAnimation() : void
   {
       if (this._stepAnimTimer)
       {
           this._stepAnimTimer.removeEventListener('complete', this.onStepAnimTimerStep);
           this._stepAnimTimer.pause();
       }
   }

   //=================================================================================================================

    private onStepAnimTimerStep = () => {

        // if (this._ctrlKeyON == true)
        // {
        //     console.log("in anim but control is on");
        //     return;
        // }

        if (this._stepAnimTimer.getState() === ANIMTIMER_State.ANIM_STATE_RUNNING )
        {
            if (this._stepAnimDirection == ANIM_DIRECTION.FORWARD)
                this.onNext(null);
            else
                this.onPrev(null);
            
           if (this._stepAnimTimer.getState() === ANIMTIMER_State.ANIM_STATE_RUNNING )
           {
               this._stepAnimTimer.removeEventListener('complete', this.onStepAnimTimerStep);
               this._stepAnimTimer.reStart(this._cursel_dbids_array_index_pos, this._currentAnimStepDuration);
               this._stepAnimTimer.addEventListener('complete', this.onStepAnimTimerStep);
               this._stepAnimTimer.setStepIndex(this._cursel_dbids_array_index_pos + 1);
           }
       }
   }

  //===================================================================================================================

  private callback_getSubset = (dbIds) => {

      this.getSubset(dbIds, this._searchPropertyName, this._searchDisplayValue, this.callback_getNodeFragmentsByIdList);
  }

   //===================================================================================================================

  private callback_SearchError = (error) => {
  
       alert("in search.doSearchError");
  }
  //===================================================================================================================

   private callback_getNodeFragmentsByIdList = (dbIds) => {

       if (ExtensionUtil.isValidContent(dbIds))  
       {
           const instanceTree = this._viewer.model.getData().instanceTree;
           const ids          = [];

           for (let id of dbIds)   
           {
               const fragIds = []

               instanceTree.enumNodeFragments(id, function (fragId) {  fragIds.push(fragId); });

               if (fragIds.length > 0) 
               {
                   ids.push(id);

                   const x_id:number = id.dbId;
                   let id_exists     = false;

                   // now check if already exist

                   for(let currId of this._cursel_dbids_array) 
                   { 
                       if (currId === x_id)
                       {
                           id_exists=true;
                           break;
                       }
                   }
                   if (!id_exists)
                   {
                       this._cursel_dbids_array.push( x_id );
                   }
               }
           }
           this.writeToLogFile( "[in start]   selection has : " + this._cursel_dbids_array.length + "  objects");
               
           this.checkValidLeafComponents();
           
           this.createItemNumbersLookupTable();

           if (this._guiPanel.getRandomState())
           {
               this.canDoNext(1);
           } 
           this.doPlay();
       }
   }

   //=================================================================================================================

   private checkValidLeafComponents()
   {
       const that = this;

        ViewerToolkit.getAllLeafComponents(this._viewer, function ( dbids ) {

            let all_Leaf_Nodes_dbids:any = [];

            all_Leaf_Nodes_dbids = [...dbids ];   

            // all dbids of the cursel-dbids_array ..MUST be found in the arry of all 
            // leaf-components of the current model !!

            for (let currLeafNode of that._cursel_dbids_array) 
            {
                if (all_Leaf_Nodes_dbids.indexOf(currLeafNode) < 0)
                {
                   //alert("Warning: the element with dbId " + currLeafNode + " has NOT been found in the current model-leaf-components and had been removed !");

                    const index = that._cursel_dbids_array.indexOf( currLeafNode );
                    if (index > -1) 
                    {
                        that._cursel_dbids_array.splice(index, 1);
                    }
                }
            }
        });       
   }

   //=================================================================================================================

   public setData(dataArray:number[])
   {
       this.resetSelectionArray();

       this._cursel_dbids_array = dataArray;
   }

   //=================================================================================================================

   private onResetClicked(e:any) : void 
   {
        this._stopRotateAnimNow = false;

         this._guiPanel.onClosePanel();

        this._isBasketPanelVisible = false;
        this.container.style.width = this._initialWidth + "px";

        this.turnOffAllAnimations();  

        this.resetAnimTransform();

        this._viewer.clearSelection(); 

        this._viewer.isolate();   // reset isolation !!!

       ViewerToolkit.isolateFull( this._viewer, null,  null );

        this._viewer.fitToView();

        this.resetSelectionArray();
   }
  
   //=================================================================================================================

    public startRotateAnimation(animSpeedFactor:number)
    {
        if (animSpeedFactor == 0)
        {
            this.stopRotateAnimation();
        }
        else
        {
            this._rotAnimDirection = ANIM_DIRECTION.FORWARD;
            if (animSpeedFactor < 0)
            {
                this._rotAnimDirection = ANIM_DIRECTION.BACKWARD;
            }
            const speedValue = Math.abs( animSpeedFactor);
            const factor     =   1.001 -  ((speedValue - 0.0001)  / 100.0);

            this._stopRotateAnimNow=false;

            if ( this._rotAnimDirection == ANIM_DIRECTION.FORWARD )
            {
                const deltaDuration                 = this._rotAnim360MaxDurationForward - this._rotAnim360MinDuration;
                this._currentRotAnim360RotDuration  = this._rotAnim360MinDuration +  ( factor * deltaDuration);
            }
            else
            {
                const deltaDuration                 = this._rotAnim360MaxDurationBackward - this._rotAnim360MinDuration;
                this._currentRotAnim360RotDuration  = this._rotAnim360MinDuration +  ( factor * deltaDuration);
            }
            if (this._rotateAnimRequestId == 0)
            {
                this.startRotateAnim();            
            }
        }
    }

   //=================================================================================================================

    public startRotateAnim() : void 
    {
        if (ExtensionUtil.isValidContent(this._cursel_dbids_array))  
        {
            const nodeId = this._cursel_dbids_array[ this._cursel_dbids_array_index_pos]; 
            this.onInitRotateAnim(nodeId);    
        }
    }
  
    //=================================================================================================================

    public stopRotateAnimation()
    {
        if (this._rotateAnimRequestId) 
        {
            this._stopRotateAnimNow = true;
            window.cancelAnimationFrame(this._rotateAnimRequestId);
            this._rotateAnimRequestId = 0;
        }
    };

    //=================================================================================================================
  
   private getSubset(dbIds:any, name:string, value:string, callback:Function) 
   {
       if (ExtensionUtil.isValidContent(name)) {

           this._viewer.model.getBulkProperties(
           dbIds,
           {
               propFilter:     [name],
               ignoreHidden:   true,
           },
           function (data) {

               if (ExtensionUtil.isValidContent(data)) {

                   let items:any[] = [];

                   for (let key in data) {

                       let item = data[key];

                       if (value) 
                       {
                           if (item.properties[0].displayValue === value)
                           {
                               items.push(item);
                               //items.push(item.dbId);
                           }
                       }
                   }
                   callback(items);
               }
           },
           function (error) { console.log(error); }
           );
       }
   }
   
   //=================================================================================================================

   private writeToLogFile(info:any) : void
   {
       if (this._writeLogFile)
           console.log(info);
   }
     
   //=================================================================================================================

       
    //=================================================================================================================


   public getFragmentWorldMatrixByNodeId = (nodeId:number) => {

       let result = {
             fragId: [],
             matrix: [],
       };

       const that = this;

       this._instanceTree.enumNodeFragments(nodeId, function  (frag)   {

         let fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, frag);
         let matrix = new THREE.Matrix4();
         fragProxy.getWorldMatrix(matrix);

         result.fragId.push(frag);
         result.matrix.push(matrix);

       });

       return result;
     }    

   //=================================================================================================================

   public assignTransformations = (refererence_dummy, nodeId:number)  =>  {

       refererence_dummy.parent.updateMatrixWorld(true);  // wjx 3.12.20 ...parameter is neccessary now

       var position = new THREE.Vector3();
       var rotation = new THREE.Quaternion();
       var scale    = new THREE.Vector3();

       refererence_dummy.matrixWorld.decompose(position, rotation, scale);

       //  console.log("decomposed matrix into position = " + JSON.stringify(position));

       const that = this;


       this._instanceTree.enumNodeFragments(nodeId, function (frag) {

           var fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, frag);
           
           fragProxy.getAnimTransform();
           
           fragProxy.position   = position;
           fragProxy.quaternion = rotation;
           fragProxy.updateAnimTransform();
       });

     }

   //=================================================================================================================

   private randomIndex (min, max) : number
   {
        return Math.floor(Math.random() * (max - min + 1)) + min;
   }

   //=================================================================================================================

    private resetAnimTransform() : void
    { 
        if (ExtensionUtil.isValidContent( this._cursel_dbids_array ) && this._instanceTree != null)
        {
            const that = this;

            for (let curr_dbId of this._cursel_dbids_array)                    
            {
                this._instanceTree.enumNodeFragments(curr_dbId, function (frag) {

                    var fragProxy = that._viewer.impl.getFragmentProxy(that._viewer.model, frag);
        
                    fragProxy.getAnimTransform();

                    const position = new THREE.Vector3();
                    const rotation = new THREE.Quaternion(0, 0, 0, 1);
    
                    fragProxy.position   = position;
                    fragProxy.quaternion = rotation;
                    fragProxy.updateAnimTransform();
                });
            }
            this._viewer.impl.sceneUpdated(true);
        }
    }

   //=================================================================================================================

    public getDbIdsArrayLength()
    {
        return this._cursel_dbids_array.length;
    }

   //=================================================================================================================

    public basketShow() : void
    {
        let newWidth =  this._initialWidth + "px";

        if (!this._isBasketPanelVisible)
        {
            newWidth = this._basketPanelWidth.toString() + "px";
        }
        this.container.style.width   =  newWidth;  
        this._isBasketPanelVisible = !this._isBasketPanelVisible;
    }

    //=================================================================================================================

    public basketAddSelectedObjects() : string
    {
        let itemNumbers_already_exits = null;

        if (this._guiPanel !== null)
        {
            const dbIds = this._viewer.getSelection(); // if selection is set, we change automaticly to 

            if (ExtensionUtil.isValidContent( dbIds ))       // should NOT be undefined,null,empty-Array..or number with value 0
            {
                let counter = 0;
                let currentSelection = [];
    
                currentSelection = ( Array.isArray(dbIds) ) ? dbIds : [dbIds]

                for (let curr_dbId of currentSelection)
                {
                    const item:BasketDataStruct = this._itemNumberLookupTable.find( e => e.DBID == curr_dbId);

                    if (ExtensionUtil.isValidContent( item ))           
                    {
                        const newItem:BasketDataStruct = ({ DBID:           curr_dbId, 
                                                            ItemNumber:     item.ItemNumber,
                                                            Product:        item.Product});    

                        if (this._basketPanel.addItem(newItem,counter++))
                        {
                            if (itemNumbers_already_exits == null)
                                itemNumbers_already_exits = item.ItemNumber;
                            else
                                itemNumbers_already_exits += " " + item.ItemNumber;
                        }
                    }
                }
            }
        }
        return itemNumbers_already_exits;
    }

    //=================================================================================================================

    public basketRemoveSelectedRows() 
    {
        let retval:number;

        retval = this._basketPanel.removeSelectedRows();

        if (retval == -1)
        {
            this.clearViewerSelection();
        }
        if (retval > 0)
        {
            this.basketSelectionChanged(  retval , null);  // retval => dbId
        }
    }

    //=================================================================================================================

    public basketSelectionChanged(dbId:number,item_number:string)
    {
        const selectedData = this._basketPanel.getSelectedRows(); 

        if (selectedData !== null && selectedData.length > 0)
        {
            const all_dbIds_to_select:number[] = [];

            this.clearViewerSelection(); 
        
            for (let currSelectedRow of selectedData)
            {
                const item:BasketDataStruct = this._itemNumberLookupTable.find( e => e.DBID == currSelectedRow.getData().DBID);

                if (ExtensionUtil.isValidContent( item ))           
                {
                    const items = this._itemNumberLookupTable.filter( e => e.ItemNumber == item.ItemNumber);

                    if (ExtensionUtil.isValidContent( items ))           
                    {
                        let items_with_desired_item_number = null;                                

                        items_with_desired_item_number = Array.isArray( items) ? items : [items]
                        
                        for (let currItem of items_with_desired_item_number )
                        {
                            const dataItem = currItem as BasketDataStruct;
                            all_dbIds_to_select.push( dataItem.DBID);         
                        }
                    }
                }
            }
            if (ExtensionUtil.isValidContent( all_dbIds_to_select ))   
            {
                if (all_dbIds_to_select.length == 1)
                {
                    const dbId = all_dbIds_to_select[0];
                    this._viewer.select(  dbId ); 
                }
                else
                {
                    this._viewer.select( all_dbIds_to_select ); 
                }
            }
        }
        else
        {
            this.clearViewerSelection(); 
        }
    }

    //==================================================================================================================

    public doNavigate( naviType:NavigationType )
    {
        if (this.isBasketVisible())
        {
            let dbid_to_select:number = 0;

            if ( ( dbid_to_select = this._basketPanel.doNavigate(naviType, this.getJumpStep() ))  > 0 )
            {
                this.basketSelectionChanged( dbid_to_select, null); 
            }
        }
    }

    //==================================================================================================================

    public async basketProcessDownload(spinner:SpinnerCore)
    {
        if (this.isBasketVisible())
        {
            if (this._basketPanel.getBasketSelectionCount() > 0)  
            {
                HtmlUtils.showHtmlElement("id-player-mainpanel01",false);
                HtmlUtils.showHtmlElement("id-player-mainpanel02",false);
                HtmlUtils.showHtmlElement("id-player-basketpanel01",false);

                spinner.show(true);

                await this._revitFileUtil.processDownloadSignedUrlForSelectedItems(this._viewer,
                                                     this._basketPanel.getSelectedRows(),
                                                     this._apiClient,
                                                     spinner.getSpinnerCaptionId(),this._guiPanel );       

                spinner.show(false);

                HtmlUtils.showHtmlElement(  spinner.getSpinnerCaptionId(),  false);
                HtmlUtils.showHtmlElement("id-player-mainpanel01",true);
                HtmlUtils.showHtmlElement("id-player-mainpanel02",true);
                HtmlUtils.showHtmlElement("id-player-basketpanel01",true);
            }
        }
    }

    //==================================================================================================================

    public selectBasketRowByViewerSelection()
    {
        // try to find selected item-number in the basket-list
        // if only 1 dbid is selected in the model
        // if only 1 row is selected in the basket

        if (this.isBasketVisible())
        {
            if (this.getViewerSelectionCount() == 1)
            {
                // if (this.getBasketValidSelectionCount() <= 1)
                {
                    const currentViewerSelection:number[] = this._viewer.getSelection();

                    this._basketPanel.selectRowByViewerSelection(currentViewerSelection);
                }
            }
        }
    }

    //=================================================================================================================

    public searchItemNumberInModel(itemNumber:string) 
    {
        if (this.isBasketVisible())
        {
            const all_dbIds_to_select:number[] = [];
            
            const items = this._itemNumberLookupTable.filter( e => e.ItemNumber == itemNumber);

            if (ExtensionUtil.isValidContent( items ))           
            {
                let items_with_desired_item_number = null;                                

                items_with_desired_item_number = Array.isArray( items) ? items : [items]
                
                for (let currItem of items_with_desired_item_number )
                {
                    const dataItem = currItem as BasketDataStruct;
                    all_dbIds_to_select.push( dataItem.DBID);         
                }

                if (ExtensionUtil.isValidContent( all_dbIds_to_select ))   
                {
                    this._viewer.select( all_dbIds_to_select ); 
                }
            }
        }
    }

    //==================================================================================================================

    public isBasketVisible() : boolean
    {
        return this._isBasketPanelVisible;
    }

    //==================================================================================================================

    public getJumpStep() : number
    {
        return this._jumpStep;
    }
   
    //==================================================================================================================

    public clearViewerSelection()
    {
        this._viewer.select();  
    }

    //==================================================================================================================

    public hasViewerValidSelection()
    {
        return  (ExtensionUtil.isValidContent( this._viewer.getSelection() )) ? true : false;     
    }

    //==================================================================================================================

    public getViewerSelectionCount()
    {
        let count = 0;
        if (ExtensionUtil.isValidContent( this._viewer.getSelection()))
        {
            count = this._viewer.getSelection().length;
        }        
        return count;
    }

    //==================================================================================================================

    public getBasketRowCount()
    {
        return  this._basketPanel.getBasketRowCount();
    }

    public getBasketSelectionCount()
    {
        return  this._basketPanel.getBasketSelectionCount();
    }

    //==================================================================================================================

    public onViewerSelectionChanged = () => { 

        this._guiPanel.onViewerSelectionChanged();
    }

    //==================================================================================================================

    public onViewerModelRemoved = () => { 

        this._guiPanel.onViewerModelRemoved();
    }

    //==================================================================================================================

    async createItemNumbersLookupTable()
    {
        if (ExtensionUtil.isValidContent( this._cursel_dbids_array ))   
        {
            const that = this;
            await this.doCreateItemNumbersLookupTable().then(function(result) {

                const uniqueListOfItemNumbers:BasketDataStruct[] = [];
                const xuniqueListOfItemNumbers:string[] = [];

                for ( let currItem of that._itemNumberLookupTable)
                {
                    const item = uniqueListOfItemNumbers.find(e => e.ItemNumber == currItem.ItemNumber);
                    if (item == null)
                    {
                        uniqueListOfItemNumbers.push(currItem);
                        xuniqueListOfItemNumbers.push(currItem.ItemNumber);
                    }
                }
                that._guiPanel.init(xuniqueListOfItemNumbers);
            })
            .catch((error) => { 
           
                alert(error);

            });            
        }
    }

    //=================================================================================================================

    async doCreateItemNumbersLookupTable()
    {
        const that = this;

        for (let curr_dbId of  this._cursel_dbids_array)
        {
            await ViewerToolkit.getProperty02(this._viewer.model, curr_dbId, "item number").then(function(result) {

                const result_objProperty1 = result as IPropertiesResult;

                ViewerToolkit.getProperty02(that._viewer.model, curr_dbId, "product description").then(function(result) {

                    const result_objProperty2 = result as IPropertiesResult;
                    //==========================================================
                    if ( result_objProperty1.propertyValue != "0" )
                    {
                        const newItem:BasketDataStruct = ({ DBID: curr_dbId, 
                            ItemNumber:     result_objProperty1.propertyValue,
                            Product:        result_objProperty2.propertyValue});    

                        that._itemNumberLookupTable.push(newItem);
                    }
                });   
            })
            .catch((error) => { 

                // alert("Err:3201 " + error );
                          
            });   
        }
        //alert("in doreateLookup fertig");
    }

    //=================================================================================================================

    private onInitRotateAnim(currentNodeId:number) : void
    {
        //alert("rotate-mode is ON"); 

        if ( this._pin_Id  !== 0 && this._pin_Id !== currentNodeId)
        {
            this._seconds_pivot.rotation.z = 0.0; 
            this.assignTransformations(this._seconds_helper, this._pin_Id);
         //   bei rotanimstop pin_id auf null setzen
        }

 
        if ( this._instanceTree == null)
             this._instanceTree = this._viewer.model.getData().instanceTree;
 
        this._seconds_pivot  = new THREE.Mesh( new THREE.BoxGeometry(0.1, 0.1, 0.1),  new THREE.MeshBasicMaterial({ color: 0xff0000 }));
        this._seconds_helper = new THREE.Mesh( new THREE.BoxGeometry(0.5, 0.5, 2),    new THREE.MeshBasicMaterial({ color: 0x0000ff }));
        
        // viewer.impl.scene.add(seconds_pivot); // makes THREE geometry (boxes) visible in viewer
        /////////////////
        
        //some nodes might contain several fragments, but in our case we know it has one fragment
        // World position of Fragment Object to rotade
 
        this._pin_Id        = currentNodeId;
        const secondsArm_Id = this._pin_Id;
        //==========================================
        // check if can rotate with the current element ..if we can retain matrix and position
        //==========================================
 
         let bCanRotate = false;
         const frag_world_mat = this.getFragmentWorldMatrixByNodeId( this._pin_Id );
 
         if (frag_world_mat !== undefined)
         {
             if ( frag_world_mat.matrix !== undefined)
             {
                 if ( frag_world_mat.matrix.length > 0)
                 {
                     bCanRotate= true;
                 }
             }
         }
 
         if ( !bCanRotate )
         {
             alert("cannot use rotation with element dbId = " + this._pin_Id);                
         }
         else
         {
             let pin_position = this.getFragmentWorldMatrixByNodeId(this._pin_Id).matrix[0].getPosition().clone();
             this._seconds_pivot.position.x = pin_position.x;
             this._seconds_pivot.position.y = pin_position.y;
             this._seconds_pivot.position.z = pin_position.z;
             ///////////////
             let secondsArm_position = this.getFragmentWorldMatrixByNodeId(secondsArm_Id).matrix[0].getPosition().clone();
 
             this._seconds_pivot.add(this._seconds_helper); // add helper to pivot not to scene directly
 
             this._seconds_helper.updateMatrixWorld(true);    // wjx 3.12.20 this has changed... parameter now os necessary true or false ?
 
             var seconds_helper_wold_position = new THREE.Vector3();
             this._seconds_helper.localToWorld(seconds_helper_wold_position);
             //console.log("Helper's World position = " + JSON.stringify(seconds_helper_wold_position));
 
             // calculate off set between Helper and Arm
             this._seconds_helper.position.x = - secondsArm_position.x - Math.abs(secondsArm_position.x - this._seconds_pivot.position.x);
             this._seconds_helper.position.y = - secondsArm_position.y - Math.abs(secondsArm_position.y - this._seconds_pivot.position.y);
             this._seconds_helper.position.z = - secondsArm_position.z - Math.abs(secondsArm_position.z - this._seconds_pivot.position.z);
             this._viewer.impl.sceneUpdated(true);
 
             this._rotateAnimRequestId = window.requestAnimationFrame(this.doRotateAnimation);
         }
    }
 
    //=================================================================================================================
 
    private doRotateAnimation = (animTime:number)  => {
        
        if (this._rotateAnimRequestId <= 0)
        {
          //  this._rotateAnimRequestId = window.requestAnimationFrame(this.doRotateAnimation);
            return;
        }
 
        if (animTime == 0)
        {
            this._currentAnimFrame = 0.0;
            this._animLastTime     = 0.0;
        }
 
        if (this._currentAnimFrame == 1)
        {
        }
 
        const delta_time_in_ms = animTime - this._animLastTime;
        this._animLastTime     = animTime;
        this._currentAnimFrame += 1.0;
        let currentRotationStep:number = 0.01;
 
        if (delta_time_in_ms > 0.0)
        {
            const PHI_2            = Math.PI * 2.0;
            const delta_in_ms      = delta_time_in_ms;
            const desired_angle    = 360.0;
            const time_for_360_degree_spin_in_ms = this._currentRotAnim360RotDuration; // we want to spin the object 360 degrees in a time duration of xxx ms !!!!!

            let calls_to_get_360_degrees = time_for_360_degree_spin_in_ms / delta_in_ms; // 5000 / 10 ====>  500 calls to get 360 degrees
 
            let stepAngle_per_call_in_deg  = desired_angle / calls_to_get_360_degrees;   //  360 / 500  = 0.72 degrees per call     check: 0.72 * 500 = 360
 
            let stepAngle_per_call_in_rad  = PHI_2 * ( stepAngle_per_call_in_deg / desired_angle) ;   // 6.28 * (0.72 / 360)  ====> 0.01256
 
            currentRotationStep   = stepAngle_per_call_in_rad;     // 0.01256;   da nach 500 calls ein spin von 6.28 ( what is 360 degrees )
 
            //console.log("delta-in-ms = " + delta_in_ms + " ")
            //========================================================
            this._seconds_pivot.rotation.z += currentRotationStep; 
            this.assignTransformations(this._seconds_helper, this._pin_Id);
            this._viewer.impl.sceneUpdated();
            //========================================================
        }
 
        // 0.017463 ==> <= 1 degree rotate     0.0087315  ==> <= 0.5 degree rotate
 
        if ( currentRotationStep >  0.0087315)
        {
            // dont call renderloop 
        }    
 
        window.requestAnimationFrame(this.doRotateAnimation);
    };    
        
     //=================================================================================================================
 




}



